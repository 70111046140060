import { Component, Input, OnInit, AfterViewInit, inject, ChangeDetectorRef } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { initalLoadingTackles, LoadingTackles, RailOrder, WagonInformation } from "../../../../../../models/rail-order-api";
import { RailOrderInternalService } from "src/app/order-management/service/rail-order-internal.service";
import { CodeNamePair } from "src/app/order-management/models/general-order";
import { ValidationMode } from "../../../../validators/validator-field.config";
import { WagonValidationService } from "../../../../service/wagon-validation-service.service";
@Component({
  selector: 'app-loading-tackles-list',
  templateUrl: './loading-tackles-list.component.html',
  styleUrls: ['./loading-tackles-list.component.scss']
})
export class LoadingTacklesListComponent implements OnInit, AfterViewInit {

  @Input() railOrder: RailOrder;
  @Input() formGroup: FormGroup;
  @Input() wagonInformation: WagonInformation;
  @Input() validationMode: ValidationMode;
  
  protected loadingTackles: CodeNamePair[] = []; 
  private wagonValidationService: WagonValidationService = inject(WagonValidationService);

  constructor(private fb: FormBuilder, private railOrderInternalService: RailOrderInternalService, private cd: ChangeDetectorRef) {
    this.createDropdownFields();
  }

  ngOnInit(): void {
    this.initForm();    
  }

  ngAfterViewInit(): void {
    this.addLinesToForm();
    this.cd.detectChanges(); 
    this.wagonValidationService.validateSingleWagon( this.railOrder, this.wagonInformation, this.validationMode, this.formGroup);
  }

  private createDropdownFields(): void {
    this.createLoadingTackes();
  }

  private createLoadingTackes(): void {
    this.railOrderInternalService.getLoadingTackles().subscribe((result: CodeNamePair[]) => {
      this.loadingTackles = result;      
    }); 
  }

  private addLinesToForm() {
    if (this.wagonInformation.loadingTackles.length === 0) {
      this.addNewLine();
    } else if (this.wagonInformation.loadingTackles.length !== this.loadingTacklesList.length) {
      this.setFormValues();
    }
  }

  private initForm(): void {
    this.formGroup.addControl('loadingTacklesList', this.fb.array([]));    
  }

  private setFormValues() {
    this.loadingTacklesList.clear();
    if (this.wagonInformation.loadingTackles?.length) {
      this.wagonInformation.loadingTackles.forEach((item: LoadingTackles) => {
        this.addNewLine(item);
      });
    } else {
      this.addNewLine();
    }
  }
  
  protected getFormValues(): LoadingTackles[] {
    return this.loadingTacklesList.controls.map((group: FormGroup) => ({
      number: group.get('numberOfLoadingTackles')?.value,
      type: group.get('type')?.value,
      weight: group.get('weight')?.value,
      identifier: group.get('identifier')?.value
    }));
  }
  
  protected get loadingTacklesList(): FormArray {
    return this.formGroup.get('loadingTacklesList') as FormArray;
  }

  protected getControl(i: number, controlName: string): FormControl {
    return this.loadingTacklesList.at(i).get(controlName) as FormControl;
  }
  
  public getNumberOfLoadingTackles(i: number): FormControl {
    return this.getControl(i, 'numberOfLoadingTackles');
  }

  public getType(i: number): FormControl {
    return this.getControl(i, 'type');
  }

  public getIdentifier(i: number): FormControl {
    return this.getControl(i, 'identifier');
  }

  public getWeight(i: number): FormControl {
    return this.getControl(i, 'weight');
  }

  protected addNewLine(item?: LoadingTackles): void {
    const newItem = item || initalLoadingTackles();
    
    const itemGroup: FormGroup = this.fb.group({
      numberOfLoadingTackles: new FormControl(newItem.number, Validators.required),
      type: new FormControl(newItem.type, Validators.required),
      identifier: new FormControl(newItem.identifier, Validators.required),
      weight: new FormControl(newItem.weight, Validators.required)
    });
    this.loadingTacklesList.push(itemGroup);
    if (!item) {
      this.wagonInformation.loadingTackles.push(newItem);
    }
  }

  protected removeLine(idx: number): void {
    if (this.loadingTacklesList.length > 1) {
      this.loadingTacklesList.removeAt(idx);
      this.wagonInformation.loadingTackles.splice(idx, 1);
    }
  }
  
  isRowModified(rowIndex: number): boolean {
    return this.loadingTacklesList.at(rowIndex)?.dirty || false;
  }
/*
  isControlInvalid(rowIndex: number, controlName: string): boolean {
    const control = this.getControl(rowIndex, controlName);
    return this.isRowModified(rowIndex) && control.invalid && (control.touched || control.dirty);
  }
    */
}