import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DBUIElementsModule } from '@db-ui/ngx-elements-enterprise/dist/lib';
import { TranslateModule } from '@ngx-translate/core';

export interface GenericErrorData {
  code?: number;
  httpError?: HttpErrorResponse;
  header?: string;
  message?: string;
  error?: any;
}

@Component({
  selector: 'app-generic-error',
  standalone: true,
  imports: [CommonModule, TranslateModule, DBUIElementsModule],
  templateUrl: './generic-error.component.html',
  styleUrl: './generic-error.component.scss'
})
export class GenericErrorComponent {
  protected errorData: GenericErrorData;
  protected errorProperties: {key: string, value: any}[] = [];
  protected httpErrorProperties: {key: string, value: any}[] = [];

  constructor(private dialogRef: MatDialogRef<GenericErrorComponent>,
    @Inject(MAT_DIALOG_DATA) data: GenericErrorData) {
    this.errorData = data;
    if(this.errorData.error) {
      Object.keys(this.errorData.error).forEach(property => this.errorProperties.push({key: property, value: this.errorData.error[property]}));
    }
    if(this.errorData?.httpError?.error && !this.errorData?.httpError?.error?.errors) {
      Object.keys(this.errorData?.httpError?.error).forEach(property => {
        if(this.showProperty({key: property, value: this.errorData.httpError?.error[property]})) {
          this.httpErrorProperties.push({key: property, value: this.errorData?.httpError?.error[property]});
        }
    });
    }
  }

  private showProperty(prop: {key: string, value: any}) {
    return prop?.value && (typeof prop.value == 'string' || typeof prop.value == 'number');
  }

  protected close() {
    this.dialogRef.close();
  }
}
