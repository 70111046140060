import { Component, ElementRef, Inject } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { debounceTime } from "rxjs";
import { Subject } from "rxjs/internal/Subject";
import { SelectedAutoCompleteItem } from "src/app/shared/components/form-dialog/autocomplete/autocomplete.component";
import { AutocompleteModule } from "src/app/shared/components/form-dialog/autocomplete/autocomplete.module";
import { SharedModule } from "src/app/shared/shared.module";
import { ApiDangerousGoodResponse, DangerousGoodObject, DangerousGoodModel } from "src/app/trainorder/models/Cargo.model";
import { TrainorderService } from "src/app/trainorder/services/trainorder.service";

@Component({
  selector: 'app-dangerous-goods-selection-dialog',
  templateUrl: './dgs.component.html',
  styleUrl: './dgs.component.scss',  
  standalone: true,
  imports: [ ReactiveFormsModule, FormsModule, SharedModule, AutocompleteModule]

})
export class DangerousGoodsSelectionDialogComponent {
  
  private selectedItem: DangerousGoodObject;
  private dangerousGoods: DangerousGoodObject[] = [];

  protected autocompleteFieldName: string = 'unNr';
  protected selectedIndex: number = -1;
  protected formGroup: FormGroup;
  protected currentlyVisableDangerousGoods: DangerousGoodObject[] = [];
  protected currentPage: number = 0;
  protected totalPages: number = 0;
  protected autocompleteResultList: DangerousGoodModel[] = [];
  
  constructor(private translate: TranslateService,
              private dialogRef: MatDialogRef<DangerousGoodsSelectionDialogComponent>,               
              private trainorderService: TrainorderService) {
    this.createForm();                
  }

  private createForm(): void {
    this.formGroup = new FormGroup({});
    this.formGroup.addControl(this.autocompleteFieldName, new FormControl());
  }

  protected requestSelectionListItems(input: string): void {
    this.currentlyVisableDangerousGoods = [];
    this.trainorderService.getDangerousCargoInfo(input).then((result: ApiDangerousGoodResponse) => {
      this.autocompleteResultList = result;
    });
  }

  protected onSelectAutocompleteItem(selectedItem: SelectedAutoCompleteItem): void {
      if (selectedItem?.code?.length === 0) {
        this.dangerousGoods = [];
        this.createCurrentlyVisableDangeousGoods();
      }
      this.selectUnNumber(selectedItem.code);
    }

  protected previous(): void {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.createCurrentlyVisableDangeousGoods();
    }
  }

  protected next(): void {
    if ((this.currentPage +1) < this.totalPages) {
      this.currentPage++;
      this.createCurrentlyVisableDangeousGoods();
    }
  }

  protected previousIsActive(): boolean {
    return this.currentPage > 0;
  }

  protected nextIsActive(): boolean {
    return (this.currentPage +1) < this.totalPages;
  }

  protected cancel() {
    this.dialogRef.close(null);
  }

  protected confirm(): void {
    this.dialogRef.close(this.selectedItem);
  }

  protected translatedPlaceholder(): string {
    return this.translate.instant('New-order.Wagon-information.Placeholder.UN-number');
  }

  protected translatedTitle(): string {
    return this.translate.instant('New-order.Wagon-information.Placeholder.UN-number');
  }

  private selectUnNumber(input: string): void {
    if (input) {      
      this.trainorderService.getDangerousCargo(input).then((result: DangerousGoodObject[]) => {
        this.dangerousGoods = result;
        this.createCurrentlyVisableDangeousGoods();        
      });      
    }    
  }

  private createCurrentlyVisableDangeousGoods(): void {
    const totalItems: number = this.dangerousGoods?.length || 0;
    this.totalPages = Math.floor(totalItems / 4);
    if (totalItems % 4 > 0) this.totalPages++;

    const currentItems: DangerousGoodObject[] = [];
    let position = 0;
    position = this.currentPage * 4;
    for (let idx = position; idx < ((this.currentPage +1) * 4); idx++) {
      if (this.dangerousGoods.at(idx)) {
        currentItems.push(this.dangerousGoods.at(idx));
      }
    }
    this.currentlyVisableDangerousGoods = currentItems;
  }  

  protected highlight(idx: number): string {
    if (idx + (this.currentPage * 4) === this.selectedIndex) {
      return 'highlight';  
    }
    return '';
  }

  protected selectDangerousGoodItem(idx: number, selectedItem: DangerousGoodObject): void {
    this.selectedItem = selectedItem;
    this.selectedIndex = (this.currentPage * 4 + idx);
  }

  protected trackByFn(index: any, item: any): any {
    return index;
  }
}