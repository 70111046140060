import { AbstractControl, FormGroup, ValidationErrors } from "@angular/forms";
import { PackingUnit, RailOrder, WagonInformation } from "../../../models/rail-order-api";

export class BaseValidators {
    
    
    static firstGoodNhmValidator(goodNhmCode: string | null, group: AbstractControl) {
        
    const isInternationalTransport = BaseValidators.isInterNationalTransportByControl(group as FormGroup);
    const invalidNhmCodeError =  { invalidNhmCode: true };
        
    if(!goodNhmCode) return invalidNhmCodeError;
    
    // If not international transport no further checks
    if (!isInternationalTransport) return null;

    // Check NHM code validity: must be 6 characters and not start with '00'
    if(goodNhmCode.trim().length !== 6) return invalidNhmCodeError;
    if (goodNhmCode.startsWith('00')) return invalidNhmCodeError;
    return null;
    }

    static loadingStateRequiredValidator(goodWeight: number, loadingState: boolean): ValidationErrors | null {
       
        // If loadingState is false, it's required

        if ( String(loadingState) == 'null') {
            return { LoadingStateRequired: true };
        }

        if (String(loadingState) == 'false' ) {
            // Check if there's weight for an empty wagon
            if (goodWeight) {
                return { EmptyWagonHavingWeight: true };
            }
           
        }

        if ((String(loadingState) == 'true' && !goodWeight)  )  {
           // return { EmptyWagonHavingWeight: true };
        }
        
        // All validations passed
        return null;
    }
    

    static wagonNumbersSet: Set<string> = new Set();

    static clearWagonNumbersSet() {
        BaseValidators.wagonNumbersSet.clear();
    }

    static wagonNumberLengthValidator(controlValue: string): ValidationErrors | null {
        let isValid = true;
        const cleanWagonNumber = controlValue?.replace(/\D/g, '') || null;
        // Validate the length of the wagon number
        if (!cleanWagonNumber || cleanWagonNumber.length !== 12) {
            isValid = false;
        }

        return isValid ? null : { EnsureValidWagonNumber: true };
    }

    static wagonNumberLuhnValidator(controlValue: string): ValidationErrors | null {
        let isValid = true;
        const cleanWagonNumber = controlValue?.replace(/\D/g, '') || null;

        // Validate the length of the wagon number
        if (cleanWagonNumber) {
            const isLuhn = BaseValidators.validateLuhn(cleanWagonNumber);
            if (!isLuhn) {
                isValid = false;
            }
        }

        return isValid ? null : { invalidChecksum: true };
    }

    static wagonNumberDuplicateValidator(controlValue: string): ValidationErrors | null {

        let isValid = true;
        const cleanWagonNumber = controlValue?.replace(/\D/g, '') || null;


        // Validate the length of the wagon number

        if (cleanWagonNumber && BaseValidators.wagonNumbersSet.has(cleanWagonNumber)) {
            isValid = false;
        } else if (cleanWagonNumber) {
            BaseValidators.wagonNumbersSet.add(cleanWagonNumber);
        }

        return isValid ? null : { duplicateNumber: true };
    }

    static wagonNumberIsNumeric(controlValue: string): ValidationErrors | null {
        let isValid = true;
         // Remove spaces, hyphens, and any non-numeric characters
    const cleanWagonNumber = controlValue.replace(/[\s-]/g, '');

    // Validate if the cleaned value is numeric and matches the expected conditions
     isValid = cleanWagonNumber.length > 11 && !isNaN(Number(cleanWagonNumber));

    return isValid ? null : { EnsureValidWagonNumber: true };
    }

    /**
     * Validate a number using the Luhn Algorithm.
     * @param number - The string representation of the number to validate.
     * @returns true if the number is valid according to Luhn's algorithm, false otherwise.
     */
    static validateLuhn(number: string) {

        let sum = 0;
        let shouldDouble = false;
        if (!number || number=='') return true;
        // Process digits from right to left
        for (let i = number.length - 1; i >= 0; i--) {
            let digit = parseInt(number[i], 10);

            if (shouldDouble) {
                digit *= 2;
                if (digit > 9) {
                    digit -= 9;
                }
            }

            sum += digit;
            shouldDouble = !shouldDouble;
        }

        return sum % 10 === 0;
    }


    // Static method to validate type of wagon
    static typeOfWagonValidator(wagonNumber: string, typeOfWagon: string): ValidationErrors | null {
        const missingWagonTypeError =  { noTypeOfWagon: true };
    
        const cleanWagonNumber = wagonNumber?.replace(/\D/g, '') || null;
        const isLuhn = BaseValidators.validateLuhn(cleanWagonNumber);
        if (!cleanWagonNumber && !typeOfWagon) return missingWagonTypeError;
        if(cleanWagonNumber.length ==0 && !typeOfWagon) return missingWagonTypeError;
        if (!wagonNumber && !typeOfWagon) return missingWagonTypeError;
        if (!isLuhn && !typeOfWagon) return missingWagonTypeError;
        return null;
    }

    // Static method to validate first good weight

    static goodWeightReqiredValidator(goodWeight: number, loadingState: boolean, isWeightRequired: boolean): ValidationErrors | null {
        
        if ( String(loadingState) == 'true') {
            if (isWeightRequired && !goodWeight) {
                return { noGoodWeight: true }
            } 
            
        }
        return null;
    }

    static valueHasNoDecimal(weight: number): ValidationErrors | null {
        if (weight === null || weight === undefined) {
            return null; // No weight, no validation error.    
        } 
        const value = weight.toString();
        if (value.includes(',') || value.includes('.')) {
            return { decimalPlacesGoodWeight: true };
        }
        return null; // Valid case. 
    }

    static valueIsPositiv(value: number): ValidationErrors | null {
        let isValid = (value !== null) && (value < 0) ? false : true;
        return isValid ? null : { noGoodWeight: true };
    }

    static isNationalTransport(railOrder: RailOrder): boolean {
        const pickupCountry = String(railOrder?.acceptancePoint?.authority)
        const deliveryCountry = String(railOrder?.deliveryPoint?.authority);
        return pickupCountry === '80' && deliveryCountry === '80';
    };

    static isMultiNationalTransport(railOrder: RailOrder): boolean {
        const pickupCountry = String(railOrder?.acceptancePoint?.authority)
        const deliveryCountry = String(railOrder?.deliveryPoint?.authority);
        return pickupCountry !== '80' && deliveryCountry !== '80';
    };

    static isInterNationalTransport(railOrder: RailOrder): boolean {
        const pickupCountry = String(railOrder?.acceptancePoint?.authority)
        const deliveryCountry = String(railOrder?.deliveryPoint?.authority);
        return pickupCountry !== '80' || deliveryCountry !== '80';
    };

    static isWagonWeightTooHigh(wagon: WagonInformation): ValidationErrors | null {
        let isValid = true;
     
        // Calculate loading tackles weight (ensure parsing to number)
        const loadingTacklesWeight = wagon.loadingTackles?.reduce(
            (sum, tackle) => sum + Number(tackle.weight || 0), 0
        ) || 0;
     
        // Calculate goods weight (ensure parsing to number)
        const goodsWeight = wagon.goods?.reduce(
            (sum, good) => sum + Number(good.weight || 0), 0
        ) || 0;
     
        // Check if total weight exceeds the limit
        if ((loadingTacklesWeight + goodsWeight) > 600000) {
            isValid = false;
        }
     
        return isValid ? null : { wagonWeightTooHigh: true };
    }

    static EiuEvuWithoutPermissionNumber(imCode: string, permissionNumber: string): ValidationErrors | null {
        // If both imCode and permissionNumber are absent, no validation error
        if (!imCode && !permissionNumber) {
            return null;
        }
        // If imCode exists but permissionNumber is missing, return validation error
        if (!imCode && permissionNumber) {
            return { hasImCodeWithoutPermissionNumber: true };
        }
        // Otherwise, no validation error
        return null;
    }

    static PermissionNumberWithoutEiuEvu(imCode: string, permissionNumber: string) {
        // If both imCode and permissionNumber are absent, no validation error
        if (!imCode && !permissionNumber) {
            return null;
        }
        // If permissionNumber exists but imCode is missing, return validation error
        if (!permissionNumber && imCode) {
            return { hasPermissionNumberWithoutImCode: true };
        }
        // Otherwise, no validation error
        return null;
    }

    static hasVolumeWithoutUnit(volume: number, unit: string): ValidationErrors | null {
        // If both volume and unit are absent, no validation error
        if (!volume && !unit) {
            return null;
        }

        // If volume exists but unit is missing, return validation error
        if (volume && !unit) {
            return { hasVolumeWithoutUnit: true };
        }

        // Otherwise, no validation error
        return null;
    }

    static hasUnitWithoutVolume(volume: number, unit: string): ValidationErrors | null {
        // If both volume and unit are absent, no validation error
        if (!volume && !unit) {
            return null;
        }

        // If unit exists but volume is missing, return validation error
        if (!volume && unit) {
            return { hasUnitWithoutVolume: true };
        }

        // Otherwise, no validation error
        return null;
    }

    static packingUnitNumberRequiredValidator(packingUnit: PackingUnit): ValidationErrors | null {
        if (!packingUnit.number && !packingUnit.type) {
            return null;
        }

        // If type exists but number is missing, return validation error
        if (packingUnit.type && !packingUnit.number) {
            return { packingUnitNumberRequired: true };
        }

        // Otherwise, no validation error
        return null;
    }

    static packingUnitTypeRequiredValidator(packingUnit: PackingUnit): ValidationErrors | null {
        if (!packingUnit.number && !packingUnit.type) {
            return null;
        }

        // If type exists but number is missing, return validation error
        if (!packingUnit.type && packingUnit.number) {
            return { packingUnitTypeRequired: true };
        }

        // Otherwise, no validation error
        return null;
    }

    static wasteIdRequiredValidator(modeOfTransport: string | null, wasteId: number | null): ValidationErrors | null {
        // If modeOfTransport is 'AT' and wasteId is missing, return the error
        return modeOfTransport === 'AT' && !wasteId ? { wasteIdRequired: true } : null;
    }

    static validateMrnIdentifierByType(mrnIdentifier: string | null, mrnSubType: string | null): ValidationErrors | null {
        let hasError = false;
        const mrnSubTypeToValidate = ['03', '04', '05', '06', '07', '08', '09'];
      
        if (mrnSubTypeToValidate.includes(mrnSubType)) {
          if (['03', '04', '05'].includes(mrnSubType)) {
            // Validate MRN 03, 04, 05: 18 characters alphanumeric (21 with "MRN")
            if (!mrnIdentifier?.match(/^(MRN)?[a-zA-Z0-9]{18}$/)) {
                hasError = true;
            }
          } else if (['06', '07', '08', '09'].includes(mrnSubType)) {
            // Validate MRN 06, 07, 08, 09: Max 21 characters alphanumeric
            if (!mrnIdentifier?.match(/^[a-zA-Z0-9]*$/) || mrnIdentifier.length > 21) {
                hasError = true;
            }
          }
        }
      
        // Return errors if any, otherwise null
        return (hasError) ? { invalidFormatMrn: true } : null;
      }

    static MrnSubTypeRequiredValidator(mrnIdentifier: string | null, mrnSubType: string | null): ValidationErrors | null {
        if (!mrnIdentifier && !mrnSubType) {
            return null;
        }
        return (mrnIdentifier && !mrnSubType) ? { mrnSubTypeRequired: true } : null;
    }

    
    static isNationalTransportByControl(group: FormGroup): boolean {
        const pickupCountry = group.get('pickupDelivery').get('pickupCountry')?.value;
        const deliveryCountry = group.get('pickupDelivery').get('deliveryCountry')?.value;
        return pickupCountry === '80' && deliveryCountry === '80';
    };
    
    static isMultiNationalTransportByControl(group: FormGroup): boolean {
        const pickupCountry = group.get('pickupDelivery').get('pickupCountry')?.value;
        const deliveryCountry = group.get('pickupDelivery').get('deliveryCountry')?.value;
        return pickupCountry !== '80' && deliveryCountry !== '80';
    };
    
    static isInterNationalTransportByControl(group: FormGroup): boolean {
        const pickupCountry = group.get('pickupDelivery').get('pickupCountry')?.value;
        const deliveryCountry = group.get('pickupDelivery').get('deliveryCountry')?.value;
        return pickupCountry !== '80' || deliveryCountry !== '80';
    };
}