import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { SelectFieldSettings } from '../form-dialog.model';

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  styleUrl: './select-field.component.scss'
})
export class SelectFieldComponent implements OnInit, AfterViewInit {
  @Input() settings: SelectFieldSettings;

  protected fieldName: string;

  ngOnInit(): void {
    this.fieldName = this.settings.name ? this.settings.name : this.settings.id;
  }

  // protected formGroup: FormGroup;
  ngAfterViewInit(): void {
    // this.formGroup = (this.settings.formControl.parent as FormGroup);
  }

  protected displayValue(option: any) {
    return this.settings.optionTransform.transform(option)
  }

  protected errorCondition(): boolean {
    if (typeof this.settings.showErrorCondition !== undefined && this.settings.showErrorCondition) {
      return this.settings.showErrorCondition;
    }
    return !(this.settings.formControl?.valid || this.settings.formControl?.disabled);
  }

  protected doOnChange($event) {
    if(this.settings.onChange) {
      this.settings.onChange($event);
    }
  }
}
