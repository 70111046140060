import { AfterViewInit, ChangeDetectorRef, Component, inject, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { WagonInformation, ExternalReference, RailOrder, initialExternalReference } from "../../../../../../models/rail-order-api";
import { EmptyPackaging } from "src/app/shared/enums/unit.enum";
import { CodeNamePair } from "src/app/order-management/models/general-order";
import { ValidationMode } from "../../../../validators/validator-field.config";
import { WagonValidationService } from "../../../../service/wagon-validation-service.service";

@Component({
  selector: 'app-customer-reference',
  templateUrl: './customer-reference.component.html',
  styleUrls: ['./customer-reference.component.scss']
})

export class CustomerReferenceComponent implements OnInit, AfterViewInit {
  @Input() railOrder: RailOrder;
  @Input() formGroup: FormGroup;
  @Input() editMode: boolean;
  @Input() wagonInformation: WagonInformation;
  @Input() validationMode: ValidationMode;

  protected emptyPackaging: CodeNamePair[] = [];  
  private wagonValidationService: WagonValidationService = inject(WagonValidationService);
  
  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef) { 
    this.createDropdownFields();    
  }
  
  ngOnInit(): void {
    this.initForm();       
  }

  ngAfterViewInit(): void {
    this.addLinesToForm();
    this.cd.detectChanges();  
    this.wagonValidationService.validateSingleWagon( this.railOrder, this.wagonInformation, this.validationMode, this.formGroup);
  }

  private createDropdownFields(): void {
    this.createEmptyPackaging();
  }

  private createEmptyPackaging(): void {
    this.emptyPackaging = [];
    Object.keys(EmptyPackaging).filter(key => {
      const codeNamePair: CodeNamePair = {
        name: key,
        code: key
      };
      this.emptyPackaging.push(codeNamePair);
    });   
  }

  private initForm(): void {
    this.formGroup.addControl('customerReferenceList', this.fb.array([]));
  }

  private addLinesToForm() {
    if (this.wagonInformation.externalReferences.length === 0) {
      this.addNewLine(null);
    } else if (this.wagonInformation.externalReferences.length != this.customerReferenceList.length) {
      this.setFormValues();
    }
  }  

  setFormValues() {
    if (this.customerReferenceList && this.customerReferenceList.length > 0) {
      this.customerReferenceList.clear();
    }
    if (this.wagonInformation && this.wagonInformation.externalReferences?.length) {
      this.wagonInformation.externalReferences.forEach((item: ExternalReference) => {
        if (item.type === "CRR" || item.type === "MRN") {
          this.addNewLine(item);  
        }
      });
    } else {
      this.addNewLine();
    }
  }
  
  protected getFormValues(): ExternalReference[] {
    const formValues: ExternalReference[] = [];

    this.customerReferenceList.controls.forEach((group: FormGroup) => {
      const item: ExternalReference = {
        type: group.get('type')?.value,
        identifier: group.get('identifier')?.value,
      };
      formValues.push(item);
    });
    console.log('getFormValues()', formValues)
    return formValues;
  }
  
  protected get customerReferenceList(): FormArray {
    return this.formGroup?.get('customerReferenceList') as FormArray;
  }

  protected getType(i: number): FormControl {
    return this.getControl(i, 'type') as FormControl;
  }

  protected getIdentifier(i: number): FormControl {
    return this.getControl(i, 'identifier') as FormControl;
  }

  protected getControl(i: number, controlName: string): FormControl {
    // Dynamically get the control at index `i` with the specified controlName
    return this.customerReferenceList.at(i).get(controlName) as FormControl;
  }
  
  protected addNewLine(item?: ExternalReference | null): void {
    if (item == null || !item) {
      item = initialExternalReference();
      item.type = 'CRR'
      this.wagonInformation.externalReferences.push(item)
    }

    // Define form group with controls, setting "type" to "CRR" and making it read-only if no reference is provided
    const itemGroup: FormGroup = this.fb.group({
      type: new FormControl({ value: item ? item.type : 'CRR', disabled: true }),  // Always "CRR" and read-only
      identifier: new FormControl(item ? item.identifier : ''),                   // Initialize identifier field
    });

    // Add this form group to the form array
    this.customerReferenceList.push(itemGroup);
  }

  protected removeLine(idx: number): void {
    if (this.customerReferenceList.length > 1) {
      this.customerReferenceList.removeAt(idx);
      this.wagonInformation.externalReferences.splice(idx, 1);
    }
  }
}