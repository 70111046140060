import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { InputFieldSettings } from '../form-dialog.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrl: './input-field.component.scss'
})
export class InputFieldComponent implements AfterViewInit {
  @Input() settings: InputFieldSettings;

  protected placeholderText: string;
  protected requiredMarker: string = '*';

  private translate: TranslateService = inject(TranslateService);
  private cdref: ChangeDetectorRef = inject(ChangeDetectorRef);

  ngAfterViewInit(): void {
    if (this.settings.requiredMarker) {
      this.requiredMarker = this.settings.requiredMarker;
    }
    this.placeholderText = this.translate.instant(this.settings.i18n.label);
    if (this.settings.showRequired) {
      this.placeholderText += this.requiredMarker;
    }
    this.cdref.detectChanges();
  }

  protected errorCondition(): boolean {
    if (typeof this.settings.showErrorCondition !== undefined && this.settings.showErrorCondition) {
      return this.settings.showErrorCondition;
    }
    return !(this.settings.formControl?.valid || this.settings.formControl?.disabled);
  }

}
