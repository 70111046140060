import { Pipe, PipeTransform } from '@angular/core';
import { Customer, Site } from 'src/app/trainorder/models/ApiCustomers.model';
import { customerSgvNameFormat } from '../constants/Constants';

@Pipe({
  name: 'customerSgvName'
})
export class CustomerSgvNamePipe implements PipeTransform {

  transform(value: Customer|null|string|Site, ...args: unknown[]): string|Customer {
    if(value == null) return '';

    if((value as Site).partnerId && !(value as Customer).sgvNumber) {
      return this.transformPartnerId(value as Site);
    }

    if(typeof value == 'string') {
      const endSgv = value.indexOf(') ');
      const sgv = value.substring(1, endSgv);
      const name = value.substring(endSgv + 2);
      return {name: name, sgvNumber: sgv};
    }

    return customerSgvNameFormat.replace('$sgv', (value as Customer).sgvNumber).replace('$name', (value as Customer).name);
  }

  private transformPartnerId(value: Site): string {
    return customerSgvNameFormat.replace('$sgv', value.partnerId).replace('$name', value.name);
  }

  public reverse(formattedString: string): Customer {
    const arr = formattedString.split(' ');
    if(arr.length < 2) {
      throw 'Error in using unformatting CustomerSgvName';
    }
    const sgv = arr[0].substring(1, arr[0].length - 1);
    const c: Customer = {name: arr[2], sgvNumber: sgv};
    return c;
  }
}
