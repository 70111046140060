import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GenericErrorComponent, GenericErrorData } from '../generic-error/generic-error.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorDialogService {

  constructor(private dialog: MatDialog) { }

  public openApiErrorDialog(error: any): MatDialogRef<GenericErrorComponent> {
    const data: GenericErrorData = {};
    if(error instanceof HttpErrorResponse) {
      data.httpError = error
    } else {
      data.error = error
    }
    return this.dialog.open(GenericErrorComponent, {data: data});
  }
}
