/**
 * VERWENDUNG: Die Autocomplete-Komponente wird folgendermaßen eingebunden:
 * 
 * <app-autocomplete [listItemCodeAttributeName]="'key'"
                     [listItemDescriptionAttributeName]="'value'"
                     [formGroup]="formGroup"
                     [fieldName]="autocompleteFieldName"
                     [placeholder]="translatedPlaceholder()"
                     [title]="translatedTitle()"
                     [autocompleteResultList]="autocompleteResultList"
                     (doRequestSelectionListItems)="requestSelectionListItems($event)"
                     (onSelectItem)="onSelectAutocompleteItem($event)">
  </app-autocomplete>

  Die Parameter haben dabei folgende Bedeutung:

  listItemCodeAttributeName:          Der Name des Key-Attributes eines Key-Value-Objektes, welcher den Code des Listeneintrages enthält. Bei Selektierung
                                      eines Listeneintrages wird dieser Code an die einbindende Komponente zurückgeliefert (siehe onSelectItem).
  
  listItemDescriptionAttributeName:   Der Name des Value-Attributes eines Key-Value-Objektes, welcher den Listeneintrag enthält.

  formGroup:                          Das FormGroup-Objekt, in das das Autocomplete-Eingabefeld integriert wird. 
                                      Achtung: Die Formgruppe muss initialisiert sein und ein FormControl-Objekt mit dem Namen 
                                      beinhalten, welcher im Attribut 'fieldName' angegeben wird (siehe unten).
  
  fieldName:                          Der Name des Eingabefeldes. Dieser wird gleichzeitig auch verwendet als ID des Eingabefeldes und als formControlName 
                                      im reactiveForm. Beim Initialisieren des FormGroup-Objektes muss also ein FormControl-Objekt hinzugefügt werden, das als
                                      Namen den Wert dieses Attributs hat (formGroup.get(<fieldName>) as FormControl). 
  
  placeholder:                        Der Wert, dieses Attributs wird als 'Placeholder' des Eingabefeldes verwendet.

  title:                              Der Wert, dieses Attributs wird als 'title' des Eingabefeldes verwendet (mouseover).

  autocompleteResultList:             Die einbindende Komponente ist für die Bereitstellung der Daten für die Listeneinträge der Autocomplete-Komponente zuständig 
                                      (siehe doRequestSelectionListItems). Diese Daten werden in Form eine Arrays aus Key-Value-Objekten über dieses Attribut an die 
                                      Autocomplete-Komponente übergeben und dort in der OnChanges-Methode verarbeitet.

  doRequestSelectionListItems:        Die einbindende Komponente stellt eine Methode bereit, die von der Autocomplete-Komponente aufgerufen wird, um den Abruf der Daten
                                      für die Listeneinträge anzustoßen. Der Parameter $event enthält ein SelectedAutoCompleteItem-Objekt (siehe unten).

  onSelectItem:                       Die einbindende Komponente stellt eine Methode bereit, die von der Autocomplete-Komponente aufgerufen wird, wenn ein Listeneintrag
                                      selektiert wurde. Der Parameter $event enthält ein SelectedAutoCompleteItem-Objekt (siehe unten).
                                                                            
  SelectedAutoCompleteItem-Objekt:    Das Objekt besitzt die Attribute code und fieldname. Das Attribut code enthält den Key-Wert des Key-Value-Objektes des selektieren 
                                      Listeneintrages. Das Attribut fieldname enthält den Wert des Attributs 'fieldName' (siehe oben), um bei Verwendung mehrerer Autocomplete-Komponenten
                                      in der einbindenden Komponente, die Rückmeldungen unterscheiden zu können.
 */

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject } from "rxjs";

export class SelectedAutoCompleteItem {
  constructor(private _code: string, private _fieldname: string) { }  
  get code() { return this._code; }
  get fieldname() { return this._fieldname; }
}

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrl: './autocomplete.component.scss'
})
export class AutocompleteComponent implements AfterViewInit, OnChanges {

  @Input() formGroup: FormGroup;        
  @Input() fieldName: string;                                 // Unique input field name
  @Input() listItemCodeAttributeName: string;                 // Selection list item code attribute name
  @Input() listItemDescriptionAttributeName: string;          // Selection list item description attribute name
  @Input() minInputLength: number = 3;
  @Input() autocompleteResultList: any[];
  @Input() placeholder: string;
  @Input() title: string;
    
  @Output() doRequestSelectionListItems = new EventEmitter<string>();
  @Output() onSelectItem = new EventEmitter<SelectedAutoCompleteItem>();
  
  protected publicDatalist: any[] = [];
  protected busy: boolean;

  private privateDatalist: any[] = [];
  private inputChange$: Subject<string> = new Subject<string>();
  private currentInput: string;

  constructor(private elementRef:ElementRef) { }

  ngAfterViewInit(): void {          
    if (!this.fieldName || this.fieldName.trim().length === 0) {
      console.error('Failed to setup autocomplete component. Missing field name.');
      return;
    } 
    if (!this.formGroup) {
      console.error('FormGroup object not yet initialized.');
      return;      
    }
    this.registerForInputChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['autocompleteResultList']) {
      this.privateDatalist = changes['autocompleteResultList'].currentValue;
      if (this.privateDatalist?.length > 0) {

        // find matching entries
        let tmp: any[] = [];
        for (let item of this.privateDatalist) {
          if (item[this.listItemCodeAttributeName].includes(this.currentInput)) {
            tmp.push(item);
          }
        }
        this.privateDatalist = tmp;
        // remove double entries
        tmp = [];
        for (let item of this.privateDatalist) {
          const found = tmp.find(entry => { return item.unCode === entry.unCode; });
          if (!found) {
            tmp.push(item);
          }
        }
        this.privateDatalist = tmp;

        if (this.privateDatalist?.length === 1) { // If the result list contains only one entry, this is selected automatically
          this.onSelect(this.privateDatalist[0][this.listItemCodeAttributeName]);
          const x = this.elementRef.nativeElement.querySelector('#' + this.fieldName);      
          x.dispatchEvent(new KeyboardEvent('keydown', { key: 'Enter', code: 'Enter', which: 13, keyCode: 13}));
          //this.formGroup.get(this.fieldName).setValue('');
          this.privateDatalist = [];
        } else if (this.privateDatalist?.length > 1) { // If the results list contains more than one entry, the selection list is created and displayed.          
          for (let item of this.privateDatalist) {
            this.publicDatalist.push(item);
          }          
          this.updateDataListEventListener();
        }        
        this.busy = false;
      }
    }
  }

  protected autocompleteInputChanged(event: any): void {
    this.inputChange$.next(event.target.value);  
  }

  protected clear(): void {
    this.formGroup.get(this.fieldName).setValue(''); 
    this.onSelect('');
  }

  protected inputFieldHasContent(): boolean {
    return this.formGroup?.get(this.fieldName)?.value?.length > 0;
  }

  private onSelect(code: string): void {
    const selectedItem = new SelectedAutoCompleteItem(code, this.fieldName);
    this.onSelectItem.emit(selectedItem);          
  }

  private addListener(e) {
    let qsli = document.querySelector('#' + e.target.getAttribute('list'));
    var options = qsli.querySelectorAll('option');
    let values = [];
    [].forEach.call(options, function(option) {
      values.push(option.value);
    });    
    let currentValue = e.target.value;
    if (values.indexOf(currentValue) !== -1) {
      this.onSelect(currentValue);      
    }
  }

  private updateDataListEventListener(): void {
    const x = this.elementRef.nativeElement.querySelector('#' + this.fieldName);
    x.removeEventListener('change', this.addListener.bind(this));
    let qsli = document.querySelector('#' + x.getAttribute('list'));
    var options = qsli.querySelectorAll('option');
    let values = [];
    [].forEach.call(options, function(option) {
      values.push(option.value);
    });
    x.addEventListener('change', this.addListener.bind(this));    
  }
  
  private registerForInputChanges(): void {
    this.inputChange$.subscribe(() => {
      this.requestSelectionListContent();
    });
  }

  private requestSelectionListContent(): void {
    this.publicDatalist = [];
    this.currentInput = this.formGroup.get(this.fieldName).value;
    if (this.currentInput?.length >= this.minInputLength) {
      this.busy = true;
      this.doRequestSelectionListItems.emit(this.currentInput);
    }
  }
}