import { inject, Pipe, PipeTransform } from "@angular/core";
import { DangerousGoodLaw } from "src/app/order-management/models/general-order";
import { dangerousGoodLawFormat } from "../constants/Constants";
import * as moment from "moment";
import { AppService } from "src/app/app.service";

@Pipe({
    name: 'dangerousGoodLawPipe'
  })
  export class DangerousGoodLawPipe implements PipeTransform {
    private appService = inject(AppService);

    transform(value: DangerousGoodLaw): string {
        if(value == null) return '';
        moment.locale(this.appService.language.code);
        const validFrom = value.validFrom ? moment(value.validFrom).format('DD.MM.yyyy') : '';
        const validTo = value.validTo ? moment(value.validTo).format('DD.MM.yyyy') : '';
        return dangerousGoodLawFormat.replace('$year', `${value.year}`).replace('$validFrom', validFrom).replace('$validTo', validTo).replace('$text', value.text);
    }
  }