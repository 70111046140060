import { ChangeDetectorRef, Component, inject, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ExceptionalConsignment, initialExceptionalConsignment, RailOrder, WagonInformation } from "../../../../../../models/rail-order-api";
import { RailOrderInternalService } from "src/app/order-management/service/rail-order-internal.service";
import { CodeNamePair } from "src/app/order-management/models/general-order";
import { ValidationMode } from "../../../../validators/validator-field.config";
import { WagonValidationService } from "../../../../service/wagon-validation-service.service";

@Component({
  selector: 'app-authorization-list',
  templateUrl: './authorization-list.component.html',
  styleUrls: ['./authorization-list.component.scss']
})
export class AuthorizationListComponent implements OnInit {

  @Input() railOrder: RailOrder;
  @Input() formGroup: FormGroup;
  @Input() wagonInformation: WagonInformation;
  @Input() validationMode: ValidationMode;

  private railwayCompanyChange: Subject<string> = new Subject<string>(); // | to debounce input requests
  private wagonValidationService: WagonValidationService = inject(WagonValidationService);
  protected railwayCompanyAutocomplete: CodeNamePair[] = [];


  constructor(private fb: FormBuilder, private railOrderInternalService: RailOrderInternalService, private cd: ChangeDetectorRef) {
    this.registerForInputChanges();
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit(): void {
    // Add a new line if authorizationList is empty
    if (this.authorizationList.length === 0) {
      this.addNewLine(null);
    }
    this.cd.detectChanges();
    this.wagonValidationService.validateSingleWagon(this.railOrder, this.wagonInformation, this.validationMode, this.formGroup);
  }


  private registerForInputChanges(): void {
    this.railwayCompanyChange.pipe(debounceTime(500)).subscribe((input: string) => {
      this.getRailwayCompanyAutocomplete(input);
    });
  }

  private initForm(): void {
    this.formGroup.addControl('authorizationList', this.fb.array([]));
    this.setFormValues();
  }

  private setFormValues() {
    // Clear the authorizationList only if it exists
    if (this.authorizationList && this.authorizationList.length > 0) {
      this.authorizationList.clear();
    }

    // Populate the form with values from wagonInformation, or add a new line
    if (this.wagonInformation && this.wagonInformation.exceptionalConsignments?.length) {
      this.wagonInformation.exceptionalConsignments.forEach((item: ExceptionalConsignment) => {
        this.addNewLine(item);
      });
    } else {
      this.addNewLine(null);  // Add an empty line if no consignments are provided
    }
  }

  protected getFormValues(): ExceptionalConsignment[] {
    const formValues: ExceptionalConsignment[] = [];

    this.authorizationList.controls.forEach((group: FormGroup) => {
      const item: ExceptionalConsignment = {
        imCode: group.get('imCode')?.value,
        permissionNumber: group.get('permissionNumber')?.value
      };
      formValues.push(item);
    });

    return formValues;
  }

  protected get authorizationList(): FormArray {
    return this.formGroup?.get('authorizationList') as FormArray;
  }

  protected getControl(i: number, controlName: string): FormControl {
    const group = this.authorizationList.at(i) as FormGroup;
    return group.get(controlName) as FormControl;
  }

  protected getImCode(i: number): FormControl {
    return this.getControl(i, 'imCode');
  }

  protected getPermissionNumber(i: number): FormControl {
    return this.getControl(i, 'permissionNumber');
  }

  protected addNewLine(item?: ExceptionalConsignment | null): void {
    if (item == null || !item) {
      item = initialExceptionalConsignment();
      this.wagonInformation.exceptionalConsignments.push(item);
    }

    const itemGroup: FormGroup = this.fb.group({
      imCode: new FormControl(item.imCode),
      permissionNumber: new FormControl(item.permissionNumber)
    });

    this.authorizationList.push(itemGroup);
  }

  protected removeLine(idx: number): void {
    if (this.authorizationList.length > 1) {
      this.authorizationList.removeAt(idx);
      this.wagonInformation.exceptionalConsignments.splice(idx, 1);
    }
  }

  /**
   * Emits the next input value from the field to trigger autocomplete
   * @param event
   * @param field type of the field
   */
  protected autocompleteInputChanged(event: any, field: string): void {
    switch (field) {
      case 'im-code':
        this.railwayCompanyChange.next(event.target.value);
        break;
      default:
        break;
    }
  }

  /**
   * Fetches the IM Code autocomplete options.
   * Filters results and limits to 30 items for performance.
   * @param input The input string for autocomplete
   */
  private getRailwayCompanyAutocomplete(input: string): void {
    this.railOrderInternalService.getRailwayCompanies().subscribe((result: CodeNamePair[]) => {
      this.railwayCompanyAutocomplete = result;
      this.railwayCompanyAutocomplete = result.filter(company =>
        company.code.toLowerCase().includes(input));
    });
  }
  /*
  private getRailwayCompanyAutocomplete(input: string): void {
    this.railOrderInternalService.getRailwayCompanies().subscribe((result: CodeNamePair[]) => {
      // Filter the result based on the input
      this.railwayCompanyAutocomplete = result.filter(company =>
        company.name.toLowerCase().includes(input.toLowerCase()) // Adjust the field `name` as per your API response
      );
    });
  }
    */


  /**
   * TrackBy function for efficient rendering of dynamic lists
   * @param index 
   * @param item 
   * @returns 
   */
  protected trackByFn(index: any, item: any): any {
    return index;
  }

  imCodeSelected(index: number) {
    if (!this.wagonInformation.exceptionalConsignments[index]) {
      this.addNewLine(null);
    }
    this.wagonInformation.exceptionalConsignments[index].imCode = this.getImCode(index).value;
  }

  permissionNumberChanged(index: number) {
    if (!this.wagonInformation.exceptionalConsignments[index]) {
      this.addNewLine(null);
    }
    this.wagonInformation.exceptionalConsignments[index].permissionNumber = this.getPermissionNumber(index)?.value;
  }




}
