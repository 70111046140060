import { AfterViewInit, ChangeDetectorRef, Component, inject, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { initialSeal, RailOrder, Seal, WagonInformation } from "../../../../../../models/rail-order-api";
import { ValidationMode } from "../../../../validators/validator-field.config";
import { WagonValidationService } from "../../../../service/wagon-validation-service.service";
@Component({
  selector: 'app-sealing-list',
  templateUrl: './sealing-list.component.html',
  styleUrls: ['./sealing-list.component.scss']
})

export class SealingListComponent implements OnInit, AfterViewInit {
  @Input() railOrder: RailOrder;
  @Input() formGroup: FormGroup;
  @Input() wagonInformation: WagonInformation;
  @Input() validationMode: ValidationMode;

  private wagonValidationService: WagonValidationService = inject(WagonValidationService);

  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef) {
    
  }

  ngOnInit(): void { 
    this.initForm();       
  }

  ngAfterViewInit() {
    this.addLinesToForm();    
    this.cd.detectChanges();
    this.wagonValidationService.validateSingleWagon( this.railOrder, this.wagonInformation, this.validationMode, this.formGroup);   
  }
  
  private addLinesToForm() {
    if (this.wagonInformation.seals.length === 0) {
      this.addNewLine();
    } else if (this.wagonInformation.seals.length !== this.sealingList.length) {
      this.setFormValues();
    }
  }
  
  private initForm(): void {
    this.formGroup.addControl('sealingList', this.fb.array([]));    
  }    

  private setFormValues() {
    this.sealingList.clear();

    if (this.wagonInformation.seals?.length) {
      this.wagonInformation.seals.forEach((item: Seal) => {
        this.addNewLine(item);
      });
    } else {
      this.addNewLine();
    }
  }
  
  protected getFormValues(): Seal[] {
    return this.sealingList.controls.map((group: FormGroup) => ({
      type: group.get('type')?.value,
      referenceNumber: group.get('referenceNumber')?.value
    }));
  }
  
  protected get sealingList(): FormArray {
    return this.formGroup.get('sealingList') as FormArray;
  }

  protected getControl(i: number, controlName: string): FormControl {
    return this.sealingList.at(i).get(controlName) as FormControl;
  }

  public getType(i: number): FormControl {
    return this.getControl(i, 'type');
  }

  public getReferenceNumber(i: number): FormControl {
    return this.getControl(i, 'referenceNumber');
  }
  
  protected addNewLine(item?: Seal): void {
    const newItem = item || initialSeal();

    const itemGroup: FormGroup = this.fb.group({
      type: new FormControl(newItem.type, [Validators.required, Validators.minLength(10)]),
      referenceNumber: new FormControl(newItem.referenceNumber, Validators.required)
    });

    this.sealingList.push(itemGroup);

    if (!item) {
      this.wagonInformation.seals.push(newItem);      
    }
  }

  protected removeLine(idx: number): void {
    if (this.sealingList.length > 1) {
      this.sealingList.removeAt(idx);
      this.wagonInformation.seals.splice(idx, 1);      
    }
  }
  
  isRowModified(rowIndex: number): boolean {
    return this.sealingList.at(rowIndex)?.dirty || false;
  }

  isControlInvalid(rowIndex: number, controlName: string): boolean {
    const control = this.getControl(rowIndex, controlName);
    return this.isRowModified(rowIndex) && control.invalid && (control.touched || control.dirty);
  }
}
