import { RailOrderStatus } from "src/app/order-management/models/general-order";
import { PagingResponse, PagingRequest } from "src/app/shared/models/paging";
import { CustomerProfile } from "src/app/trainorder/models/authorization";

export interface WagonSummaryRequest extends PagingRequest {
    wagonNumber?: string;
    orderNumber?: string;
    orderAuthority?: number;
    shippingDateFrom?: Date;
    shippingDateTo?: Date;
    sendingStations?: LocationRequest[];
    receivingStations?: LocationRequest[];
    currentLocations?: LocationRequest[];
    emptyWagons?: boolean
    // TODO
    consignorProfiles?: CustomerProfile[];
    consigneeProfiles?: CustomerProfile[];
}

export interface LocationRequest {
    objectKeyAlpha: string;
    objectKeySequence: number;
}

export interface WagonSummaryResponse extends PagingResponse {
    summaries: WagonSearchSummary[];
}

export interface WagonSearchSummary {
    wagonNumber?: string;
    emptyWagon?: boolean;
    suitableForRunning?: string;
    shippingTime?: Date;
    estimatedArrivalTime?: Date;
    latestArrivalTime?:Date;
    sendingStation?: string;
    receivingStation?: string;
    lastWagonEventTime?: Date;
    lastWagonEventType?: WagonSearchEventType;
    timeConstraintType?: string;
    currentLocation?: string;
    consignorName?: string;
    consigneeName?: string;
    railOrderStatus?: RailOrderStatus;
    orderId: number;
    orderKey: OrderKey;
}

export interface OrderKey {
    orderAuthority: number;
    orderNumber: number;
}

export interface InfrastructureLocationSummary {
    name: string,
    objectKeyAlpha: string,
    objectKeySequence: number
}

export interface PartnerSummary {
    name: string,
    sgvId: string,
    partnerId: string,
    ownerNumber?: string
}

export enum WagonSearchEventType {
    LOCATION_CHANGE = 'LOCATION_CHANGE',
    ACTIVATION = 'ACTIVATION',
    DEPARTURE = 'DEPARTURE',
    PASS_THROUGH = 'PASS_THROUGH',
    ARRIVAL = 'ARRIVAL',
    COMPLETION = 'COMPLETION'
}

export enum RailOrderStage {
    DRAFT = 'DRAFT',
    BOOKING = 'BOOKING',
    TRANSPORT_ORDER = 'TRANSPORT_ORDER'
}

export function railOrderStatusToString(status: RailOrderStatus): string {
    return status;
}

export enum PartnerRole {
    CONSIGNOR = 'CONSIGNOR',
    CONSIGNEE = 'CONSIGNEE'
}
