import { AfterViewInit, ChangeDetectorRef, Component, inject, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DangerousGood, ExternalReference, Goods, initialDangerousGood, initialExternalReference, initialGood, initialPackingUnit, PackingUnit, RailOrder } from "../../../../../../models/rail-order-api";
import { debounceTime, Subject } from "rxjs";
import { ApiDangerousGoodResponse, DangerousGoodObject, ApiGoodResponse, DangerousGoodModel, GoodModel } from "src/app/trainorder/models/Cargo.model";
import { TrainorderService } from "src/app/trainorder/services/trainorder.service";
import { RailOrderInternalService } from "src/app/order-management/service/rail-order-internal.service";
import { CodeNamePair } from "src/app/order-management/models/general-order";
import { EmptyPackaging, VolumeUnit } from "src/app/shared/enums/unit.enum";
import { NewOrderWagonDetailDialogService } from "../../service/new-order-wagon-detail-dialog.service";
import { MatDialogRef } from "@angular/material/dialog";
import { DangerousGoodsSelectionDialogComponent } from "./dangerous-goods-selection-dialog/dgs.component";
import { FormFieldService } from "../../../../service/form-field.service";
import { WagonValidationService } from "../../../../service/wagon-validation-service.service";
import { ValidationMode } from "../../../../validators/validator-field.config";
@Component({
  selector: 'app-goods-information-list',
  templateUrl: './goods-information-list.component.html',
  styleUrls: ['./goods-information-list.component.scss']
})
export class GoodsInformationListComponent implements OnInit, AfterViewInit {
  @Input() railOrder: RailOrder;
  @Input() idx: number; // wagon
  @Input() editMode: boolean;
  @Input() formGroup: FormGroup;
  @Input() validationMode: ValidationMode;

  private dangerousGoodIndex: number = 0;
  private nhmCodeInputChange: Subject<string> = new Subject<string>();
  private dangerousCargoInputChange: Subject<string> = new Subject<string>();
  private dangerousGoodsDialogRef: MatDialogRef<DangerousGoodsSelectionDialogComponent> = null;
  protected hasAC: boolean;
  protected isDangerousGoods: boolean[] = [false, false, false, false];
  protected emptyPackingUnitList: CodeNamePair[] = [];
  protected specialInstructionsList: CodeNamePair[] = [];
  protected scrap: CodeNamePair[] = [];
  protected packagingUnits: CodeNamePair[] = [];
  protected volumeUnits: CodeNamePair[] = [];
  protected mrnTypes: CodeNamePair[] = [];
  protected nhmCodeAutocomplete: GoodModel[] = [];
  protected dangerousGoodsAutocomplete: DangerousGoodModel[] = [];
  private formFieldService: FormFieldService = inject(FormFieldService);
  private wagonValidationService: WagonValidationService = inject(WagonValidationService);

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private newOrderWagonDetailService: NewOrderWagonDetailDialogService,
    private trainorderService: TrainorderService,
    private railOrderInternalService: RailOrderInternalService
  ) {
    this.registerForInputChanges();
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit() {
    this.createDropdownContents();
    this.hasAC = this.railOrder?.templateNumber?.trim().length > 0;
    if (!this.railOrder.wagonInformation[this.idx].goods || this.railOrder.wagonInformation[this.idx].goods.length === 0) {
      this.railOrder.wagonInformation[this.idx].goods.push(initialGood());
    }
    this.createForm();
    this.formFieldService.disableFields(this.formGroup, 'wagonDetails', this.railOrder, this.editMode, this.railOrder.orderId ? false : true);
    this.wagonValidationService.validateSingleWagon(this.railOrder, this.railOrder.wagonInformation[this.idx], this.validationMode, this.formGroup);
    this.cd.detectChanges();
  }

  private registerForInputChanges(): void {
    this.nhmCodeInputChange.pipe(debounceTime(500)).subscribe((input: string) => {
      this.getNhmCodeAutocomplete(input);
    });
    this.dangerousCargoInputChange.pipe(debounceTime(500)).subscribe((input: string) => {
      this.getDangerousCargoAutocomplete(input);
    });
  }

  private createDropdownContents(): void {
    this.createAdditionalDeclarations();
    this.createpackingUnitTypes();
    this.createVolumeUnits();
    this.createMrnTypes();
    this.createEmptyPackaging();
    this.createSpecialInstructions();
  }

  private createEmptyPackaging(): void {
    this.emptyPackingUnitList = [];
    Object.keys(EmptyPackaging).filter(key => {
      const codeNamePair: CodeNamePair = {
        name: key,
        code: EmptyPackaging[key]
      };
      this.emptyPackingUnitList.push(codeNamePair);
    });
  }

  private createSpecialInstructions(): void {
    this.railOrderInternalService.getSpecialInstructions().subscribe((result: CodeNamePair[]) => {
      this.specialInstructionsList = result;
    });
  }

  private createMrnTypes(): void {
    const allowedMrnTypes = ['03', '04', '05', '06', '07', '08', '09'];

    this.railOrderInternalService.getMrnTypes().subscribe({
      next: (result: CodeNamePair[]) => {
        // Step 1: Filter allowed MRN codes
        const filteredCodes = result.filter(item => allowedMrnTypes.includes(item.code));

        // Step 2: Get MRN references from the wagon
        const wagon = this.railOrder.wagonInformation[this.idx];
        if (wagon?.externalReferences) {
          const mrnReferences = wagon.externalReferences.filter(ref => ref.type === "MRN");

          // Add MRN references as CodeNamePair
          mrnReferences.forEach(ref => {
            filteredCodes.push({ code: ref.subType, name: ref.subType, shortName: ref.subType });
          });
        }

        // Step 3: Add an empty entry at the beginning
        filteredCodes.unshift({ code: '', name: '' });

        // Step 4: Assign filtered codes to mrnTypes
        this.mrnTypes = filteredCodes;
      },
      error: (err) => {
        console.error('Failed to fetch MRN types:', err);
      },
    });
  }



  private createpackingUnitTypes(): void {
    this.railOrderInternalService.getPackagingTypes().subscribe((result: CodeNamePair[]) => {
      this.packagingUnits = result;
    });

  }

  private createVolumeUnits(): void {
    this.volumeUnits = [];
    Object.keys(VolumeUnit).filter(key => {
      const codeNamePair: CodeNamePair = {
        name: key,
        code: key
      };

      this.volumeUnits.push(codeNamePair);
    });
    this.volumeUnits.unshift({ code: '', name: '' });
  }

  private createAdditionalDeclarations(): void {
    this.railOrderInternalService.getScrap().subscribe((result: CodeNamePair[]) => {
      this.scrap = result;
    });
  }

  private initForm(): void {
    this.formGroup.addControl('goodsInformationList', this.fb.array([]));
  }
    
  protected nhmCodeSelected(idx: number): void {
    this.railOrder.wagonInformation[this.idx].goods[idx].nhmCode = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('nhmCode').value;
  }

  protected weightInput(idx: number): void {
    this.railOrder.wagonInformation[this.idx].goods[idx].weight = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('weight').value;
  }

  protected volumeInput(idx: number): void {
    this.railOrder.wagonInformation[this.idx].goods[idx].volume = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('volume').value;
  }

  protected selectUnit(idx: number): void {
    this.railOrder.wagonInformation[this.idx].goods[idx].unit = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('unit').value;
  }

  protected selectAdditionalDeclarationCode(idx: number): void {
    this.railOrder.wagonInformation[this.idx].goods[idx].additionalDeclarationCode = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('additionalDeclarationCode').value;
  }

  protected selectPackingUnitsType(idx: number): void {
    if (!this.railOrder.wagonInformation[this.idx].goods[idx].packingUnits[0]) {
      const packingType: PackingUnit = {
        type: undefined,
        description: undefined
      };
      this.railOrder.wagonInformation[this.idx].goods[idx].packingUnits[0] = packingType;
    }
    this.railOrder.wagonInformation[this.idx].goods[idx].packingUnits[0].type = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('packingUnitsType').value;
  }

  protected packingUnitsNumberInput(idx: number): void {
    this.railOrder.wagonInformation[this.idx].goods[idx].packingUnits[0].number = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('packingUnitsNumber').value;
  }

  protected wasteIdInput(idx: number): void {
    this.railOrder.wagonInformation[this.idx].goods[idx].wasteId = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('wasteId').value;
  }

  protected selectExternalReferenceSubType(idx: number): void {
    this.railOrder.wagonInformation[this.idx].externalReferences[0].subType = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('externalReferenceSubType').value;
  }

  protected selectEmptyPackingUnitsType(idx: number): void {
    this.railOrder.wagonInformation[this.idx].goods[idx].dangerousGoods[0].emptyPackingUnit = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('emptyPackingUnit').value;
  }

  protected selectEmptyPackingUnitsCode(idx: number): void {
    const name = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('emptyPackingUnit').value;
    this.railOrder.wagonInformation[this.idx].goods[idx].dangerousGoods[0].emptyPackingUnit = name;        
  }

  protected selectSpecialInstruction(idx: number): void {
    this.railOrder.wagonInformation[this.idx].goods[idx].dangerousGoods[0].specialInstruction = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('specialInstruction').value;
  }

  protected selectWasteIndicator(idx: number): void {
    this.railOrder.wagonInformation[this.idx].goods[idx].dangerousGoods[0].wasteIndicator = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('wasteIndicator').value;
  }

  protected selectAccidentInformationSheetAvailable(idx: number): void {
    this.railOrder.wagonInformation[this.idx].goods[idx].dangerousGoods[0].accidentInformationSheetAvailable = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('accidentInformationSheetAvailable').value;
  }

  protected explosiveMassInput(idx: number): void {
    this.railOrder.wagonInformation[this.idx].goods[idx].dangerousGoods[0].explosiveMass = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('explosiveMass').value;
  }

  protected externalReferenceSubIdentifierInput(idx: number): void {
    this.railOrder.wagonInformation[this.idx].externalReferences[0].identifier = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('externalReferenceIdentifier').value;
  }

  protected customerReferenceNumberInput(idx: number): void {
    this.railOrder.wagonInformation[this.idx].goods[idx].customsReferenceNumber = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('customsReferenceNumber').value;
  }

  protected selectTransportProhibited(idx: number): void {
    this.railOrder.wagonInformation[this.idx].goods[idx].dangerousGoods[0].transportProhibited = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('transportProhibited').value;
  }

  protected additionalDescriptionInput(idx: number): void {
    this.railOrder.wagonInformation[this.idx].goods[idx].additionalDescription = (this.formGroup.get('goodsInformationList') as FormArray).controls[idx].get('additionalDescription').value;
  }

  protected addLine(): void {
    this.railOrder.wagonInformation[this.idx].goods.push(initialGood());
    this.createForm();
  }

  protected createForm(): void {
    let idx = 0;

    this.goodsInformationList.clear();
    for (let good of this.railOrder.wagonInformation[this.idx].goods) {
      let wagon = this.railOrder.wagonInformation[this.idx];
      const itemGroup: FormGroup = this.fb.group({});
      itemGroup.addControl('nhmCode', new FormControl(good.nhmCode));
      itemGroup.addControl('weight', new FormControl(good.weight));
      itemGroup.addControl('volume', new FormControl(good.volume))
      itemGroup.addControl('unit', new FormControl(good.unit));
      itemGroup.addControl('additionalDescription', new FormControl(good.additionalDescription));
      itemGroup.addControl('additionalDeclarationCode', new FormControl(good.additionalDeclarationCode));
      itemGroup.addControl('wasteId', new FormControl(good.wasteId));
      itemGroup.addControl('customsReferenceNumber', new FormControl(good.customsReferenceNumber));
      if (idx == 0) {
        // external Reference from wagon
        if (wagon?.externalReferences?.filter((el) => el.type === "MRN").length > 0) {
          wagon.externalReferences.forEach((ref: ExternalReference) => {
            if (ref.type === "MRN") {

              itemGroup.addControl('externalReferenceSubType', new FormControl(ref?.subType));
              itemGroup.addControl('externalReferenceIdentifier', new FormControl(ref?.identifier));
            }
          });
        } else {
          const ref = (initialExternalReference());
          ref.type = 'MRN';
          wagon?.externalReferences?.push(ref);
          itemGroup.addControl('externalReferenceSubType', new FormControl(''));
          itemGroup.addControl('externalReferenceIdentifier', new FormControl(''));
        }
      } else {
        // external Reference from good
        if (good?.externalReferences?.filter((el) => el.type === "MRN").length > 0) {
          good.externalReferences.forEach((ref: ExternalReference) => {
            if (ref.type === "MRN") {

              itemGroup.addControl('externalReferenceSubType', new FormControl(ref?.subType));
              itemGroup.addControl('externalReferenceIdentifier', new FormControl(ref?.identifier));
            }
          });
        } else {
          const ref = (initialExternalReference());
          ref.type = 'MRN';
          good?.externalReferences?.push(ref);
          itemGroup.addControl('externalReferenceSubType', new FormControl(''));
          itemGroup.addControl('externalReferenceIdentifier', new FormControl(''));
        }
      }
      if (good.externalReferences?.length == 0) {
        good.packingUnits.push(initialPackingUnit());

      }
      itemGroup.addControl('packingUnitsType', new FormControl(good.packingUnits[0]?.type));
      itemGroup.addControl('packingUnitsNumber', new FormControl(good.packingUnits[0]?.number));

      if (!good.dangerousGoods || good.dangerousGoods.length === 0) {
        good.dangerousGoods.push(initialDangerousGood());
      }

      const dangerousGood: DangerousGood = good.dangerousGoods[0];
      itemGroup.addControl('unNr', new FormControl(dangerousGood?.unNr));
      itemGroup.addControl('specialInstruction', new FormControl(dangerousGood?.specialInstruction));
      itemGroup.addControl('emptyPackingUnit', new FormControl(dangerousGood?.emptyPackingUnit));
      itemGroup.addControl('explosiveMass', new FormControl(dangerousGood?.explosiveMass));
      itemGroup.addControl('transportProhibited', new FormControl(dangerousGood?.transportProhibited));
      itemGroup.addControl('wasteIndicator', new FormControl(dangerousGood?.wasteIndicator));
      itemGroup.addControl('accidentInformationSheetAvailable', new FormControl(dangerousGood?.accidentInformationSheetAvailable));
      itemGroup.addControl('additionalInformation', new FormControl(dangerousGood?.additionalInformation));
      itemGroup.addControl('additionalInformationNag', new FormControl(''));
      itemGroup.addControl('dangerIdentificationNumber', new FormControl(dangerousGood?.dangerIdentificationNumber));
      itemGroup.addControl('description', new FormControl(dangerousGood?.description));
      itemGroup.addControl('class', new FormControl(dangerousGood?.class));
      itemGroup.addControl('classificationCode', new FormControl(dangerousGood?.classificationCode));
      itemGroup.addControl('packingGroup', new FormControl(dangerousGood?.packingGroup));
      itemGroup.addControl('dangerLabels', new FormControl(this.createDangerLabelsFromWagonInformation(idx)));
      itemGroup.addControl('accidentInformationSheetNr', new FormControl(dangerousGood?.accidentInformationSheetNr));
      itemGroup.addControl('nagFlag', new FormControl(dangerousGood?.nagFlag));
      idx++;
      this.goodsInformationList.push(itemGroup);

    }
  }
  protected getErrorKeys(errors: { [key: string]: any }): string[] {
    return Object.keys(errors);
  }

  protected removeLine(idx: number): void {
    if (this.goodsInformationList.length > 1) {
      this.goodsInformationList.removeAt(idx);
      const tempList: Goods[] = [];
      let counter = 0;
      for (let item of this.railOrder.wagonInformation[this.idx].goods) {
        if (counter !== idx) {
          tempList.push(item);
        }
        counter++;
      }
      this.railOrder.wagonInformation[this.idx].goods = tempList;
    }
  }

  protected get goodsInformationList(): FormArray {
    return this.formGroup?.get('goodsInformationList') as FormArray;
  }

  protected autocompleteInputChanged(event: any, field: string): void {
    switch (field) {
      case 'nhmCode':
        this.nhmCodeInputChange.next(event.target.value);
        break;
      case 'dangerous-cargo':
        this.dangerousCargoInputChange.next(event.target.value);
        break;
      default:
        break;
    }
  }

  protected trackByFn(index: any, item: any): any {
    return index;
  }

  private getNhmCodeAutocomplete(input: any): void {
    if (input.length >= 3 && !this.nhmCodeAutocomplete.find((elem) => elem.nhmCode === input)) {
      this.trainorderService.getCargoInfo(input, 6).then((result: ApiGoodResponse) => {
        // Take only 30 answers that fit (array may be 1000+ in length), otherwise it takes a lot of resources to build these elements
        this.nhmCodeAutocomplete = result.slice(0, 30).sort((a, b) => (a.nhmCode > b.nhmCode ? 1 : -1));
      });
    }
  }

  protected openDangerousGoodSelectionPopup(goodIdx: number): void {
    this.dangerousGoodsDialogRef = this.newOrderWagonDetailService.openDangerousGoodsDetailDialog();
    this.dangerousGoodsDialogRef.afterClosed().subscribe({
      next: (result: DangerousGoodObject) => {
        this.getControl(goodIdx, 'unNr')?.setValue(result?.unCode);
        this.getControl(goodIdx, 'dangerIdentificationNumber')?.setValue(result?.dangerousGoodsNumber);
        this.getControl(goodIdx, 'description')?.setValue(result?.description);
        this.getControl(goodIdx, 'class')?.setValue(result?.dangerousGoodsClass?.charAt(0));
        this.getControl(goodIdx, 'classificationCode')?.setValue(result?.dangerousGoodsClass);
        this.getControl(goodIdx, 'packingGroup')?.setValue(result?.packingGroup);

        let dangerLabels = this.createDangerLabelsFromDangerousGoodObject(result);
        this.getControl(goodIdx, 'dangerLabels')?.setValue(dangerLabels);
        this.getControl(goodIdx, 'additionalInformation')?.setValue(result?.dangerLabelInformation);
        this.getControl(goodIdx, 'accidentInformationSheetNr')?.setValue(result?.tremcardNumber);
        this.getControl(goodIdx, 'nagFlag')?.setValue(result?.nagFlag);
        this.wagonValidationService.resetDangerousGoodErrors(this.goodsInformationList?.at(goodIdx))
      }
    });
  }

  private createDangerLabelsFromDangerousGoodObject(dangerousGoodObject: DangerousGoodObject) {
    let dangerLabels = '';
    if (dangerousGoodObject?.dangerLabel1) {
      if (dangerLabels?.length > 0) {
        dangerLabels += ' / ';
      }
      dangerLabels += dangerousGoodObject.dangerLabel1;
    }
    if (dangerousGoodObject?.dangerLabel2) {
      if (dangerLabels?.length > 0) {
        dangerLabels += ' / ';
      }
      dangerLabels += dangerousGoodObject.dangerLabel2;
    }
    if (dangerousGoodObject.dangerLabel3) {
      if (dangerLabels?.length > 0) {
        dangerLabels += ' / ';
      }
      dangerLabels += dangerousGoodObject.dangerLabel3;
    }
    if (dangerousGoodObject?.dangerLabel4) {
      if (dangerLabels?.length > 0) {
        dangerLabels += ' / ';
      }
      dangerLabels += dangerousGoodObject.dangerLabel4;
    }
    return dangerLabels;
  }

  private getDangerousCargoAutocomplete(input: any): void {
    if (input.length >= 3 && !this.dangerousGoodsAutocomplete.find((elem) => elem.unCode === input)) {
      this.trainorderService.getDangerousCargoInfo(input).then((result: ApiDangerousGoodResponse) => {
        // Take only 30 answers that fit (array may be 1000+ in length), otherwise it takes a lot of resources to build these elements
        if (result) {
          this.dangerousGoodsAutocomplete = result.slice(0, 30).sort((a, b) => (a.unCode > b.unCode ? 1 : -1));
        }
      });
    }
  }

  createDangerLabelsFromWagonInformation(idx: number): string {
    let result = '';
    let index = 0;
    for (let item of this.railOrder.wagonInformation[this.idx].goods[idx]?.dangerousGoods[this.dangerousGoodIndex]?.dangerLabels) {
      index++;
      result += item;
      if (this.railOrder.wagonInformation[this.idx].goods[idx]?.dangerousGoods[this.dangerousGoodIndex]?.dangerLabels.length > index) {
        result += ' / ';
      }
    }
    
    return result;
  }

  getWagonInformationGoodsWeight(index: number): FormControl {
    return this.goodsInformationList.at(index).get('wagonInformationGoodsWeight') as FormControl;
  }

  getWagonInformationGoodsVolume(index: number): FormControl {
    return this.goodsInformationList.at(index).get('wagonInformationGoodsVolume') as FormControl;
  }

  getWagonInformationGoodsUnit(index: number): FormControl {
    return this.goodsInformationList.at(index).get('wagonInformationGoodsUnit') as FormControl;
  }

  protected getControl(index: number, controlName: string): FormControl {
    const control = this.goodsInformationList?.at(index)?.get(controlName) as FormControl
    return control;
  }

  protected isControlInvalid(Index: number, controlName: string): boolean {
    const control = this.getControl(Index, controlName);
    return false;
  }

  protected toggleDangerousGoods(idx: number): void {
    this.isDangerousGoods[idx] = !this.isDangerousGoods[idx];
    this.cd.detectChanges();
    this.wagonValidationService.validateSingleWagon(this.railOrder, this.railOrder.wagonInformation[this.idx], this.validationMode, this.formGroup);
  }
}