import { AfterViewInit, ChangeDetectorRef, Component, inject, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DangerousGood, ExceptionalConsignment, ExternalReference, Goods, LoadingTackles, PackingUnit, RailOrder, Seal, WagonInformation } from '../../../../../models/rail-order-api';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { WagonDataCommunicationService } from '../service/wagon-data-communication.service';
import { FormFieldService } from '../../../service/form-field.service';
import { ValidationMode } from '../../../validators/validator-field.config';
import { WagonValidationService } from '../../../service/wagon-validation-service.service';
import { EmptyPackaging } from 'src/app/shared/enums/unit.enum';
import { CodeNamePair } from 'src/app/order-management/models/general-order';
import { ModelService } from '../../../service/model.service';

@Component({
  selector: 'app-new-order-wagon-detail-dialog',
  templateUrl: './new-order-wagon-detail-dialog.component.html',
  styleUrl: './new-order-wagon-detail-dialog.component.scss'
})
export class NewOrderWagonDetailDialogComponent implements OnInit, AfterViewInit {

  protected idx: number;
  protected formGroup: FormGroup;
  protected isAdditionalInput: boolean;
  protected isDangerousGoods: boolean;
  protected isCustomerReference: boolean;
  protected isSealingList: boolean;
  protected isLoadingTacklesList: boolean;
  protected isAuthorizationList: boolean;
  protected wagonInformation: WagonInformation;
  protected railOrder: RailOrder;
  protected emptyPackingUnitList: CodeNamePair[] = [];
  protected index: Number;
  protected editMode: boolean;
  protected validationMode: ValidationMode;
  private wagonDataCommunicationService: WagonDataCommunicationService = inject(WagonDataCommunicationService);
  private formFieldService: FormFieldService = inject(FormFieldService);
  //private orderDialogValidationService: FormValidationService = inject(FormValidationService);
  private wagonValidationService: WagonValidationService = inject(WagonValidationService);

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { railOrder: RailOrder, idx: number, editMode: boolean, validationMode: ValidationMode },
    private dialogRef: MatDialogRef<NewOrderWagonDetailDialogComponent>,
    private fb: FormBuilder,
    private modelService: ModelService,
    private cd: ChangeDetectorRef
  ) {
    this.wagonInformation = data.railOrder.wagonInformation[data.idx];
    this.index = data.idx
    this.idx = data.idx; 
    this.railOrder = data.railOrder;
    this.editMode = data.editMode;
    this.validationMode = data.validationMode;
    this.createEmptyPackaging();
  }

  ngOnInit(): void {
    this.wagonDataCommunicationService.currentWagonInformation$.subscribe({
      next: obj => {
        if (obj.componentName == this.constructor.name) {
          return;
        }
        this.wagonInformation = obj.wagonInformation;
      }
    });
    this.createForm();

    // hier Validierung starten mit this.formGroup 
    //this.orderDialogValidationService.validateForm(this.formGroup, this.validationMode,VALIDATOR_FIELD_CONFIG);
    //this.wagonValidationService.validateWagonForm( this.formGroup, this.wagonInformation, this.validationMode);
  }

  ngAfterViewInit(): void {
    this.formFieldService.disableFields(this.formGroup, 'wagonDetails', this.railOrder, this.editMode, this.railOrder.orderId ? false : true);
    this.wagonValidationService.validateSingleWagon(this.railOrder, this.wagonInformation, this.validationMode, this.formGroup);
    this.cd.detectChanges();
  }

  private createForm(): void {
    this.formGroup = this.fb.group({});
  }
  protected getMrnReference(): ExternalReference[] | null{
    const aryExternalReference: ExternalReference[] = [];
    let goodIdx = 0;
    for (const item of this.goodsInformationListFormArray.controls) {
      if (goodIdx==0) {
      const externalReference: ExternalReference = {
        type: 'MRN',
        subType: item.get('externalReferenceSubType')?.value,
        identifier: item.get('externalReferenceIdentifier')?.value
      };
      if (externalReference.subType || externalReference.identifier) {
        aryExternalReference.push(externalReference);
        return aryExternalReference;
      }
      goodIdx++;
    }
      
    }
    return null;
  }

  private createDangerLabels(input: string): string[] {
    let result: string[] = [];
    if (input) {
      result = input.split(' / ');
    }
    return result;
  }
  private isObjectEmpty(obj: Record<string, any> | null | undefined): boolean {
    if (!obj || typeof obj !== 'object') {
      // Return true for null, undefined, or non-object values
      return true;
    }

    return Object.values(obj).every(value =>
      value == null || // Includes null and undefined
      (typeof value === 'string' && value.trim() === '') ||
      (Array.isArray(value) && value.length === 0) ||
      Number.isNaN(value)
    );
  }

  protected createGoodsModel(): Goods[] {
    const result: Goods[] = [];
    let goodsIdx=0;
    for (const item of this.goodsInformationListFormArray.controls) {
      let packingUnit: PackingUnit = undefined;
      this.railOrder.wagonInformation[this.idx].goods[0].packingUnits
      if (this.railOrder.wagonInformation[this.idx]?.goods[goodsIdx]?.packingUnits[0]?.type || this.railOrder.wagonInformation[this.idx]?.goods[goodsIdx]?.packingUnits[0]?.number) {
        packingUnit = {
          type: this.railOrder.wagonInformation[this.idx].goods[goodsIdx].packingUnits[0].type,
          number: this.railOrder.wagonInformation[this.idx].goods[goodsIdx].packingUnits[0].number
        }
      }
     
      const externalReference: ExternalReference = {
        type: 'MRN',
        subType: item.get('externalReferenceSubType')?.value,
        identifier: item.get('externalReferenceIdentifier')?.value

      };

        const dangerLabels = this.createDangerLabels(item.get('dangerLabels').value);
        const emptyPackagingCode = item.get('emptyPackingUnit').value;
        const dangerousGood: DangerousGood = {
        class: item.get('classificationCode').value?.at(0),  
        classificationCode: item.get('classificationCode').value,
        description: item.get('description').value,
        unNr: item.get('unNr').value,
        dangerIdentificationNumber: item.get('dangerIdentificationNumber').value,
        explosiveMass: item.get('explosiveMass').value,
        emptyPackingUnit: emptyPackagingCode, 
        packingGroup: item.get('packingGroup').value,
        specialInstruction: item.get('specialInstruction').value,
        transportProhibited: item.get('transportProhibited').value,
        additionalInformation: item.get('additionalInformation').value,
        dangerLabels: dangerLabels,
        accidentInformationSheetAvailable: item.get('accidentInformationSheetAvailable').value,
        accidentInformationSheetNr: item.get('accidentInformationSheetNr').value,
        nagFlag: item.get('nagFlag').value
      };
      const goods: Goods = {
        nhmCode: item.get('nhmCode').value,
        additionalDescription: item.get('additionalDescription').value,
        additionalDeclarationCode: item.get('additionalDeclarationCode').value,
        weight: item.get('weight').value,
        volume: item.get('volume').value,
        unit: item.get('unit').value,
        wasteId: item.get('wasteId').value,
        customsReferenceNumber: item.get('customsReferenceNumber').value,
        dangerousGoods: [],
        packingUnits: [],
        externalReferences : []
      }
      if(!this.isObjectEmpty(dangerousGood)) {
        goods.dangerousGoods.push(dangerousGood);
      }

      if (packingUnit) {
        goods.packingUnits.push(packingUnit);
      }

      if(goodsIdx !=0) {
        goods.externalReferences.push(externalReference)
      }
      result.push(goods);
      goodsIdx++;
    }
    return result;
  }

  private buildExternalReferences(): ExternalReference[] {
    let customerReferences: ExternalReference[] = [];
    let mrnReferences: ExternalReference[] = [];
  
    customerReferences = this.createCustomerReferences();
    mrnReferences = this.getMrnReference();
  
    const combinedReferences = [...(customerReferences || []), ...(mrnReferences || [])];
  
    return combinedReferences;
  }

  private createCustomerReferences(): ExternalReference[] {
    const customerReferences: ExternalReference[] = [];
    if (this.customerReferenceListFormArray?.controls) {
      for (let item of this.customerReferenceListFormArray?.controls) {
        const customerReference: ExternalReference = {
          type: 'CRR',
          identifier: item.value['identifier']
        }
        customerReferences.push(customerReference);
      }
    }
    return customerReferences;
  }

  private createSeals(): Seal[] {
    const seals: Seal[] = [];
    if (this.sealingListFormArray?.controls) {
      for (let item of this.sealingListFormArray.controls) {
        const seal: Seal = {
          type: item.value['type'],
          referenceNumber: item.value['referenceNumber']
        };
        seals.push(seal);
      }
    }
    return seals;
  }

  private createLoadingTackles(): LoadingTackles[] {
    const loadingTackles: LoadingTackles[] = [];
    if (this.loadingTacklesList?.controls) {
      for (const formGroup of this.loadingTacklesList.controls) {
        const loadingTackle: LoadingTackles = {
          type: formGroup.get('type').value,
          number: formGroup.get('numberOfLoadingTackles').value,
          weight: formGroup.get('weight').value,
          identifier: formGroup.get('identifier').value,
        };
        loadingTackles.push(loadingTackle);
      }
    }
    return loadingTackles;
  }

  private createAuthorizations(): ExceptionalConsignment[] {
    const authorizationList: ExceptionalConsignment[] = [];
    if (this.authorizationList?.controls) {
      for (const item of this.authorizationList.controls) {
        const authorization: ExceptionalConsignment = {
          imCode: item.get('imCode').value,
          permissionNumber: item.get('permissionNumber').value
        };
        authorizationList.push(authorization);
      }
    }
    return authorizationList;
  }

  private createEmptyPackaging(): void {
      this.emptyPackingUnitList = [];
      Object.keys(EmptyPackaging).filter(key => {
        const codeNamePair: CodeNamePair = {
          name: key,
          code: EmptyPackaging[key]
        };
        this.emptyPackingUnitList.push(codeNamePair);
      });
    }

  private updateRailOrderWagonInformation(): WagonInformation {
    const wagonInformation: WagonInformation = {
      wagonNumber: this.wagonInformation?.wagonNumber,
      loadingStatus: this.wagonInformation?.loadingStatus,
      typeOfWagon: this.wagonInformation?.typeOfWagon,
      seals: this.createSeals(),
      goods: this.createGoodsModel(),
      loadingTackles: this.createLoadingTackles(),
      externalReferences: this.buildExternalReferences(),
      exceptionalConsignments: this.createAuthorizations()
    }
    this.railOrder.wagonInformation[this.idx] = wagonInformation;
    return wagonInformation;
  }

  protected confirm() {
    this.preventEmptyPackingUnit();
    this.dialogRef.close(this.updateRailOrderWagonInformation());
  }

  private preventEmptyPackingUnit(): void {
    for (let i = 0; i < this.railOrder?.wagonInformation[this.idx]?.goods?.length; i++) {
      let tmp = [];
      if (this.railOrder?.wagonInformation[this.idx]?.goods[i]) {
        if (this.railOrder?.wagonInformation[this.idx]?.goods[i]?.packingUnits[0]?.description ||
            this.railOrder?.wagonInformation[this.idx]?.goods[i]?.packingUnits[0]?.number ||
            this.railOrder?.wagonInformation[this.idx]?.goods[i]?.packingUnits[0]?.type) {
          tmp.push(this.railOrder?.wagonInformation[this.idx]?.goods[i]?.packingUnits[0]);
        }        
      }      
      this.railOrder.wagonInformation[this.idx].goods[i].packingUnits = tmp;      
    }  
  }

  get goodsInformationListFormArray(): FormArray {
    return this.formGroup.get('goodsInformationList') as FormArray;
  }

  get customerReferenceListFormArray(): FormArray {
    return this.formGroup.get('customerReferenceList') as FormArray;
  }

  get sealingListFormArray(): FormArray {
    return this.formGroup.get('sealingList') as FormArray;
  }

  get loadingTacklesList(): FormArray {
    return this.formGroup.get('loadingTacklesList') as FormArray;
  }

  get authorizationList(): FormArray {
    return this.formGroup.get('authorizationList') as FormArray;
  }

  protected cancel() {
    this.dialogRef.close(null);
  }

  protected toggleAuthorization(): void {
    this.isAuthorizationList = !this.isAuthorizationList;
    this.cd.detectChanges();
  }

  protected toggleAdditionalInput(): void {
    this.isAdditionalInput = !this.isAdditionalInput;
    this.cd.detectChanges();
  }

  protected toggleDangerousGoods(): void {
    this.isDangerousGoods = !this.isDangerousGoods;
    this.cd.detectChanges();
  }

  protected toggleCustomerReference(): void {
    this.isCustomerReference = !this.isCustomerReference;
    this.cd.detectChanges();
  }

  protected toggleSealingList(): void {
    this.isSealingList = !this.isSealingList;
    this.cd.detectChanges();
  }

  protected toggleLoadingTackles(): void {
    this.isLoadingTacklesList = !this.isLoadingTacklesList;
    this.cd.detectChanges();
  }
}
