import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, inject, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ErrorDialogService } from "./service/api-error-dialog.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private modalWindowRef = null;
    private apiErrorDialogService: ErrorDialogService = inject(ErrorDialogService);
    constructor(private dialog: MatDialog) { }

    handleError(error: any) {
        // TODO implement error handling for different error types
        // e.g:
        if (error instanceof HttpErrorResponse) {
            console.error(error);
            return;
            try {
                const config = { data: error, width: 700 + 'px' };
                // this.modalWindowRef = this.dialog.open(GenericErrorComponent, config);
                this.modalWindowRef = this.apiErrorDialogService.openApiErrorDialog(error);
    
                this.modalWindowRef.afterClosed(() => console.log("error window closed"));
            } catch (error) {
                console.log(error);
            }
            return;
        }
        // if (error == 'test') {
        //     console.log("Test error caught...");
        //     return;
        // }
        // if (error.status && error.status == 654) {
        //     console.log("Status:", error.status);
        //     console.log("Test error caught...", error);
        //     return;
        // }
        throw error;
    }

    public closeErrorWindow() {
        if (this.modalWindowRef) { this.modalWindowRef.close(); }
    }
}