import { AfterViewInit, ChangeDetectorRef, Component, inject, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SectionName } from '../enums/order-enums';
import { RailOrder, TemplateSummary } from '../../../models/rail-order-api';
import { NewOrderConsignorConsigneeComponent } from '../new-order-sections/new-order-consignor-consignee/new-order-consignor-consignee.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NewOrderPickupDeliveryComponent } from '../new-order-sections/new-order-pickup-delivery/new-order-pickup-delivery.component';
import { NewOrderTransportComponent } from '../new-order-sections/new-order-transport/new-order-transport.component';
import { NewOrderCommercialComponent } from '../new-order-sections/new-order-commercial/new-order-commercial.component';
import { NewOrderSenderPolicyComponent } from '../new-order-sections/new-order-sender-policy/new-order-sender-policy.component';
import { NewOrderServiceComponent } from '../new-order-sections/new-order-service/new-order-service.component';
import { NewOrderWagonDataComponent } from '../new-order-sections/new-order-wagon-data/new-order-wagon-data.component';
import { NewOrderSenderPolicyModule } from '../new-order-sections/new-order-sender-policy/new-order-sender-policy.modules';
import { NewOrderCommercialModule } from '../new-order-sections/new-order-commercial/new-order-commercial.modules';
import { NewOrderConsignorConsigneeModule } from '../new-order-sections/new-order-consignor-consignee/new-order-consignor-consignee.modules';
import { NewOrderPickupDeliveryModule } from '../new-order-sections/new-order-pickup-delivery/new-order-pickup-delivery.modules';
import { NewOrderServiceModule } from '../new-order-sections/new-order-service/new-order-service.modules';
import { NewOrderTransportModule } from '../new-order-sections/new-order-transport/new-order-transport.modules';
import { NewOrderWagonDataModule } from '../new-order-sections/new-order-wagon-data/new-order-wagon-data.modules';
import { OrderInfoData } from '../models/order-info-data.model';
import { debounceTime } from 'rxjs/operators';
import { NewOrderService } from '../service/new-order.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { FormValidationService } from '../../../../shared/services/form-validation.service';
import { ValidationMode } from '../validators/validator-field.config';
import { RailOrderService } from 'src/app/order-management/service/rail-order.service';
import { RailOrderStage } from '../../wagon-view/models/api-wagon-list';
import { ErrorDialogService } from 'src/app/shared/error-handler/service/api-error-dialog.service';
import { Action } from 'src/app/order-management/models/general-order';
import { WagonValidationService } from '../service/wagon-validation-service.service';
import { ModelService } from '../service/model.service';
import { TrainorderService } from 'src/app/trainorder/services/trainorder.service';
import { DangerousGoodObject } from 'src/app/trainorder/models/Cargo.model';

@Component({
  selector: 'app-new-order-main',
  templateUrl: './new-order-main.component.html',
  styleUrl: './new-order-main.component.scss',
  standalone: true,
  imports: [
    SharedModule,
    TranslateModule,
    NewOrderConsignorConsigneeModule,
    NewOrderPickupDeliveryModule,
    NewOrderTransportModule,
    NewOrderWagonDataModule,
    NewOrderCommercialModule,
    NewOrderServiceModule,
    NewOrderSenderPolicyModule,
    MatDialogModule
  ]
})
export class NewOrderMainComponent implements AfterViewInit {

  @ViewChild(NewOrderConsignorConsigneeComponent, { static: false }) sectionConsigneeConsignor!: NewOrderConsignorConsigneeComponent;
  @ViewChild(NewOrderPickupDeliveryComponent, { static: false }) sectionPickupDelivery!: NewOrderPickupDeliveryComponent;
  @ViewChild(NewOrderTransportComponent, { static: false }) sectionTransport!: NewOrderTransportComponent;
  @ViewChild(NewOrderWagonDataComponent, { static: false }) sectionWagonData!: NewOrderWagonDataComponent;
  @ViewChild(NewOrderCommercialComponent, { static: false }) sectionCommercial!: NewOrderCommercialComponent;
  @ViewChild(NewOrderServiceComponent, { static: false }) sectionService!: NewOrderServiceComponent;
  @ViewChild(NewOrderSenderPolicyComponent, { static: false }) sectionSenderPolicy!: NewOrderSenderPolicyComponent;

  private consignorConsigneeFormGroupName = 'consignorConsignee';
  private pickupDeliveryFormGroupName = 'pickupDelivery';
  private transportFormGroupName = 'transport';
  private wagonDataFormGroupName = 'wagonData';
  private commercialFormGroupName = 'commercial';
  private serviceFormGroupName = 'service';
  private senderPolicyFormGroupName = 'senderPolicy';

  protected orderInfoData: OrderInfoData;
  protected currentSectionName: SectionName = SectionName.SECTION_CONSIGNEE_CONSIGNOR;
  protected SectionName = SectionName;
  protected nextIsVisibel: boolean = true;
  protected backIsVisibel: boolean = false;
  protected templateNumberSuggestions: TemplateSummary[] = [];
  protected headLine: string;
  protected closeButtonText: string;
  protected formGroup: FormGroup;
  protected editMode: boolean = true;
  protected isNew: boolean = false;

  protected validationStage: ValidationMode = null;

  private subscription: Subscription = new Subscription();
  private translateService: TranslateService = inject(TranslateService);
  private cd: ChangeDetectorRef = inject(ChangeDetectorRef);
  private orderDialogValidationService: FormValidationService = inject(FormValidationService);
  private wagonValidationService: WagonValidationService = inject(WagonValidationService);
  private railOrderService: RailOrderService = inject(RailOrderService);
  private apiErrorDialogService: ErrorDialogService = inject(ErrorDialogService);

  constructor(
    private dialogRef: MatDialogRef<NewOrderMainComponent>,
    private modelService: ModelService,
    private trainOrderService: TrainorderService,
    @Inject(MAT_DIALOG_DATA) data: OrderInfoData, private newOrderService: NewOrderService) {
    this.orderInfoData = data;
    this.editMode = this.orderInfoData.editMode;
    this.isNew = this.orderInfoData.isNew;
    this.setHeadLine();
  }

  ngOnInit() {
    this.setFocus();
    this.createForm();
  }

  ngAfterViewInit(): void {
    this.formInit();
    this.cd.detectChanges();
  }

  private formInit() {
    this.registerForTemplateNumberInputChanges();
    this.addSubFormGroups();
  }

  private addSubFormGroups() {
    // setTimeout(() => {
    this.formGroup.addControl(this.consignorConsigneeFormGroupName, this.sectionConsigneeConsignor.formGroup);
    // this.sectionConsigneeConsignor.formGroup.setParent(this.formGroup);
    this.formGroup.addControl(this.pickupDeliveryFormGroupName, this.sectionPickupDelivery.formGroup);
    // this.sectionPickupDelivery.formGroup.setParent(this.formGroup);
    this.formGroup.addControl(this.transportFormGroupName, this.sectionTransport.formGroup);
    // this.sectionTransport.formGroup.setParent(this.formGroup);
    this.formGroup.addControl(this.wagonDataFormGroupName, this.sectionWagonData.formGroup);
    // // this.sectionWagonData.formGroup.setParent(this.formGroup);
    this.formGroup.addControl(this.senderPolicyFormGroupName, this.sectionSenderPolicy.formGroup);
    // // this.sectionSenderPolicy.formGroup.setParent(this.formGroup);
    this.formGroup.addControl(this.serviceFormGroupName, this.sectionService.formGroup);
    // // this.sectionService.formGroup.setParent(this.formGroup);
    this.formGroup.addControl(this.commercialFormGroupName, this.sectionCommercial.formGroup);
    // this.sectionCommercial.formGroup.setParent(this.formGroup);
    this.updateAllSections();
    // });
  }

  private loadOrderObject(templateNumber: string): void {
    this.newOrderService.getRailOrderTemplateByTemplateNumber(templateNumber).subscribe({
      next: ro => {
        this.orderInfoData.railOrder = ro;
        this.setNagFlag();
        this.updateAllSections();

      },
      error: e => console.error(e)
    });
  }

  clearSearchInput(arg0: string) {
    this.templateNumberControl.setValue(null);
    this.templateNumberSuggestions = [];
  }

  private registerForTemplateNumberInputChanges(): void {
    this.subscription.add(
      this.templateNumberControl.valueChanges.pipe(debounceTime(500)).subscribe((input) => {
        if (input?.length < 3) {
          this.templateNumberSuggestions = [];
          return;
        }
        const inputkey = this.parseFormattedOrderCodeOption(input) ? this.parseFormattedOrderCodeOption(input).templateNumber : input
        const item = this.templateNumberSuggestions.find(template => template.templateName === inputkey || template.templateNumber === inputkey);
        if (item) {

          this.loadOrderObject(item.templateNumber);
          this.templateNumberSuggestions = [];
        } else if (input && input.length > 2) {
          const key = this.parseFormattedOrderCodeOption(input)?.templateNumber || input;
          this.newOrderService.getRailOrderTemplatesByQuery(key).pipe(debounceTime(500)).subscribe({
            next: (result: TemplateSummary[]) => {
              this.templateNumberSuggestions = result;
              if (this.templateNumberSuggestions.length == 1) {
                this.templateNumberControl.setValue(this.formatOrderCodeOption(this.templateNumberSuggestions[0]));
              }
            }
          });
        }
      })
    );
  }

  private setNagFlag() {
    this.orderInfoData.railOrder.wagonInformation.forEach((wagon) => {
      wagon?.goods.forEach(async (good) => {
        const unNumber = good?.dangerousGoods?.at(0)?.unNr || null;
        if (unNumber) {
          try {
            const result: DangerousGoodObject[] = await this.trainOrderService.getDangerousCargo(unNumber);
            if (result.length > 0 && result[0]?.nagFlag !== undefined) {
              good.dangerousGoods[0].nagFlag = result[0].nagFlag;
            } else {
              console.warn(`No valid NAG-Flag found for UN number: ${unNumber}`);
            }
          } catch (error) {
            console.error(`Error fetching dangerous cargo for UN number: ${unNumber}`, error);
          }
        }
      });
    });
  }

  private updateAllSections(): void {
    this.sectionConsigneeConsignor.updateRailOrder(this.orderInfoData.railOrder);
    this.sectionPickupDelivery.updateRailOrder(this.orderInfoData.railOrder);
    this.sectionTransport.updateRailOrder(this.orderInfoData.railOrder);
    this.sectionWagonData.updateRailOrder(this.orderInfoData.railOrder);
    this.sectionCommercial.updateRailOrder(this.orderInfoData.railOrder);
    this.sectionService.updateRailOrder(this.orderInfoData.railOrder);
    this.sectionSenderPolicy.updateRailOrder(this.orderInfoData.railOrder);
  }

  formatOrderCodeOption(option: TemplateSummary): string | null {
    const templateNumber = option.templateNumber ? `(${option.templateNumber})` : '';
    const templateName = option.templateName || '';

    const formatted = `${templateNumber} ${templateName}`.trim(); // Combine and trim
    return formatted ? formatted : null; // Return null if the result is empty
  }

  // Parse the formatted string back into an object
  parseFormattedOrderCodeOption(formattedOption: string): { templateNumber: string; templateName: string } | null {
    const match = formattedOption?.match(/^\(([^)]+)\)\s*(.*)$/);
    if (match) {
      return {
        templateNumber: match[1], // Captures the part inside parentheses
        templateName: match[2],  // Captures the part after the parentheses
      };
    }
    return null; // Return null if the format doesn't match
  }

  protected autocompleteInputChanged(event: any): void {
    console.error('autocompleteInputChanged not yet implemented');
  }

  private setHeadLine() {
    this.headLine = this.translateService.instant('New-order.Main.Dialog-title');
    if (this.orderInfoData.railOrder?.orderId) {
      const orderNumberDisplay = this.orderInfoData.railOrder.orderKey?.orderNumber ? this.orderInfoData.railOrder.orderKey.orderNumber : this.orderInfoData.railOrder.orderId;
      this.headLine = `${this.translateService.instant('New-order.Main.Order')} ${orderNumberDisplay}`;
    }
    this.closeButtonText = this.translateService.instant('New-order.Main.Button.Cancel');
    if (!this.editMode) {
      this.closeButtonText = this.translateService.instant('Shared.Close-button-label');
    }
  }

  private createForm(): void {
    const templateName = this.orderInfoData.railOrder?.templateName || this.orderInfoData.railOrder?.templateNumber || null;
    const option: TemplateSummary = {
      templateNumber: this.orderInfoData.railOrder?.templateNumber || null,
      templateName: this.orderInfoData.railOrder?.templateName || null
    }

    this.formGroup = new FormGroup(
      {
        templateNumber: new FormControl(this.formatOrderCodeOption(option)),
        singleConsignmentNote: new FormControl(''),

      }
    );
    if (!this.isNew) {
      this.templateNumberControl.disable();
    }
    if (!this.editMode) {
      this.formGroup.disable();
    }
  }

  protected setSection(selectedSection: SectionName): void {
    this.currentSectionName = selectedSection;
    this.setFocus();
    this.setButtonVisibility();
    switch (selectedSection) {
      case (SectionName.SECTION_CONSIGNEE_CONSIGNOR):
        this.sectionConsigneeConsignor.updateRailOrder(this.orderInfoData.railOrder);
        break;
      case (SectionName.SECTION_PICKUP_DELIVERY):
        this.sectionPickupDelivery.updateRailOrder(this.orderInfoData.railOrder);
        break;
      case (SectionName.SECTION_TRANSPORT):
        this.sectionTransport.updateRailOrder(this.orderInfoData.railOrder);
        break;
      case (SectionName.SECTION_WAGON_DATA):
        this.sectionWagonData.updateRailOrder(this.orderInfoData.railOrder);
        break;
      case (SectionName.SECTION_COMMERCIAL):
        this.sectionCommercial.updateRailOrder(this.orderInfoData.railOrder);
        break;
      case (SectionName.SECTION_SERVICE):
        this.sectionService.updateRailOrder(this.orderInfoData.railOrder);
        break;
      case (SectionName.SECTION_SENDER_POLICY):
        this.sectionSenderPolicy.updateRailOrder(this.orderInfoData.railOrder);
        break;
    }
  }

  private setButtonVisibility(): void {
    switch (this.currentSectionName) {
      case (SectionName.SECTION_CONSIGNEE_CONSIGNOR):
        this.backIsVisibel = false;
        this.nextIsVisibel = true;
        break;
      case (SectionName.SECTION_PICKUP_DELIVERY):
        this.backIsVisibel = true;
        this.nextIsVisibel = true;
        break;
      case (SectionName.SECTION_TRANSPORT):
        this.backIsVisibel = true;
        this.nextIsVisibel = true;
        break;
      case (SectionName.SECTION_WAGON_DATA):
        this.backIsVisibel = true;
        this.nextIsVisibel = true;
        break;
      case (SectionName.SECTION_COMMERCIAL):
        this.backIsVisibel = true;
        this.nextIsVisibel = true;
        break;
      case (SectionName.SECTION_SERVICE):
        this.backIsVisibel = true;
        this.nextIsVisibel = true;
        break;
      case (SectionName.SECTION_SENDER_POLICY):
        this.backIsVisibel = true;
        this.nextIsVisibel = false;
        break;
    }
  }

  protected nextSection() {
    switch (this.currentSectionName) {
      case (SectionName.SECTION_CONSIGNEE_CONSIGNOR):
        this.currentSectionName = SectionName.SECTION_PICKUP_DELIVERY
        break;
      case (SectionName.SECTION_PICKUP_DELIVERY):
        this.currentSectionName = SectionName.SECTION_TRANSPORT
        break;
      case (SectionName.SECTION_TRANSPORT):
        this.currentSectionName = SectionName.SECTION_WAGON_DATA
        break;
      case (SectionName.SECTION_WAGON_DATA):
        this.currentSectionName = SectionName.SECTION_COMMERCIAL
        break;
      case (SectionName.SECTION_COMMERCIAL):
        this.currentSectionName = SectionName.SECTION_SERVICE
        break;
      case (SectionName.SECTION_SERVICE):
        this.currentSectionName = SectionName.SECTION_SENDER_POLICY
        break;
    }

    this.setSection(this.currentSectionName);
  }

  protected backSection() {
    switch (this.currentSectionName) {
      case (SectionName.SECTION_PICKUP_DELIVERY):
        this.currentSectionName = SectionName.SECTION_CONSIGNEE_CONSIGNOR
        break;
      case (SectionName.SECTION_TRANSPORT):
        this.currentSectionName = SectionName.SECTION_PICKUP_DELIVERY
        break;
      case (SectionName.SECTION_WAGON_DATA):
        this.currentSectionName = SectionName.SECTION_TRANSPORT
        break;
      case (SectionName.SECTION_COMMERCIAL):
        this.currentSectionName = SectionName.SECTION_WAGON_DATA
        break;
      case (SectionName.SECTION_SERVICE):
        this.currentSectionName = SectionName.SECTION_COMMERCIAL
        break;
      case (SectionName.SECTION_SENDER_POLICY):
        this.currentSectionName = SectionName.SECTION_SERVICE
        break;
    }
    this.setSection(this.currentSectionName);
  }

  private setFocus() {
    document.querySelectorAll<HTMLElement>('.tabs-item').forEach(el => {
      el.classList.remove('tabs-item-selected');
      el.classList.remove('tabs-item-valid');
      if (el.id == this.currentSectionName) {
        el.classList.add('tabs-item-selected');
      }
    });
  }

  public validate(): boolean {
    let sectionConsigneeConsignorResult = this.sectionConsigneeConsignor.validate();
    if (sectionConsigneeConsignorResult?.length > 0) {
      console.log(sectionConsigneeConsignorResult);
      return false;
    }
    /*
    let section_2_result = this.section_2.validate();
    if (section_2_result?.length > 0) {
      console.log(section_2_result);
      return false;
    }
    let section_3_result = this.section_3.validate();
    if (section_3_result?.length > 0) {
      console.log(section_3_result);
      return false;
    }
      */
    return true;
  }

  sendNewOrderRequest() {
    throw new Error('Method not implemented.');
  }

  get templateNumberControl(): FormControl {
    return this.formGroup.get("templateNumber") as FormControl;
  }

  protected showOrderButton(): boolean {
    return this.orderInfoData.railOrder.allowedActions?.includes(Action.ORDER) || !this.orderInfoData.railOrder.orderId;
  }

  protected showBookButton(): boolean {
    return this.orderInfoData.railOrder.allowedActions?.includes(Action.BOOK) || !this.orderInfoData.railOrder.orderId;
  }

  protected showEditButton(): boolean {
    return this.orderInfoData.railOrder.allowedActions?.includes(Action.EDIT) || !this.orderInfoData.railOrder.orderId;
  }

  protected order() {
    console.log(this.orderInfoData.railOrder);
    this.checkRailOrder();
    this.validationStage = this.orderInfoData.railOrder.templateNumber ? ValidationMode.VALIDATORS_ORDER_AC : ValidationMode.VALIDATORS_ORDER;
    this.orderDialogValidationService.validateRailOrderForm(this.formGroup, this.validationStage);
    this.wagonValidationService.validateAllWagons(this.orderInfoData.railOrder, this.validationStage, this.formGroup);
    this.setValidStateInMenu();

    if (this.formGroup.valid) {
      this.save(RailOrderStage.TRANSPORT_ORDER);
    }
  }

  private checkRailOrder(): void {
    this.orderInfoData.railOrder.attachedDocuments = this.modelService.preventEmptyAttachedDocuments(this.orderInfoData.railOrder.attachedDocuments);
    this.orderInfoData.railOrder.wagonInformation = this.modelService.preventEmptyWagonInformation(this.orderInfoData.railOrder.wagonInformation);
    this.orderInfoData.railOrder.specialAnnotations = this.modelService.preventEmptySpecialAnnotations(this.orderInfoData.railOrder.specialAnnotations);
    this.orderInfoData.railOrder.wagonInformation = this.modelService.preventEmptyPackingUnit(this.orderInfoData.railOrder.wagonInformation);
    this.orderInfoData.railOrder.numberOfWagons = this.orderInfoData.railOrder.wagonInformation?.length || 0;
  }

  protected book() {
    console.log(this.orderInfoData.railOrder);
    this.checkRailOrder();
    this.validationStage = this.orderInfoData.railOrder.templateNumber ? ValidationMode.VALIDATORS_BOOKING_AC : ValidationMode.VALIDATORS_BOOKING;
    this.orderDialogValidationService.validateRailOrderForm(this.formGroup, this.validationStage);
    this.wagonValidationService.validateAllWagons(this.orderInfoData.railOrder, this.validationStage, this.formGroup);
    this.setValidStateInMenu();

    if (this.formGroup.valid) {
      this.save(RailOrderStage.BOOKING);
    }
  }

  protected draft() {
    this.validationStage = ValidationMode.VALIDATORS_DRAFT;
    this.orderDialogValidationService.validate(this.formGroup, ValidationMode.VALIDATORS_DRAFT);
    this.save(RailOrderStage.DRAFT);
  }

  protected consignmentButtonEnabled: boolean = true;
  protected orderButtonEnabled: boolean = true;
  protected bookButtonEnabled: boolean = true;
  protected loadingInProgress: boolean = false;

  private railOrderSaveActions = {
    next: (ro: RailOrder) => {
      // this.orderInfoData.railOrder = ro;
      // this.isNew = false;
      // this.createForm();
      // this.formInit();
      this.consignmentButtonEnabled = true;
      this.orderButtonEnabled = true;
      this.bookButtonEnabled = true;
      this.loadingInProgress = false;
      this.dialogRef.close(true);
    },
    error: (err) => {
      this.apiErrorDialogService.openApiErrorDialog(err);
      this.consignmentButtonEnabled = true;
      this.orderButtonEnabled = true;
      this.bookButtonEnabled = true;
      this.loadingInProgress = false;
    }
  };

  private save(stage: RailOrderStage): void {
    this.consignmentButtonEnabled = false;
    this.orderButtonEnabled = false;
    this.bookButtonEnabled = false;
    this.loadingInProgress = true;
    if (this.orderInfoData.railOrder.orderId && this.orderInfoData.railOrder.orderId > 0) {
      this.railOrderService.railOrdersPut(this.orderInfoData.railOrder, stage).subscribe(this.railOrderSaveActions);
      return;
    }

    this.railOrderService.railOrdersPost(this.orderInfoData.railOrder, stage).subscribe(this.railOrderSaveActions);
  }

  private setValidStateInMenu() {
    document.querySelectorAll<HTMLElement>('.tabs-item').forEach(el => {
      el.classList.remove('tabs-item-selected');
      el.classList.remove('tabs-item-valid');
      if (el.id == this.currentSectionName) {
        el.classList.add('tabs-item-selected');
      }
    });
    if (this.formGroup.get(this.consignorConsigneeFormGroupName).valid) {
      if (SectionName.SECTION_CONSIGNEE_CONSIGNOR != this.currentSectionName) {
        document.getElementById(SectionName.SECTION_CONSIGNEE_CONSIGNOR).classList.add('tabs-item-valid');
      }
    }
    if (this.formGroup.get(this.pickupDeliveryFormGroupName).valid) {
      if (SectionName.SECTION_PICKUP_DELIVERY != this.currentSectionName) {
        document.getElementById(SectionName.SECTION_PICKUP_DELIVERY).classList.add('tabs-item-valid');
      }
    }
    if (this.formGroup.get(this.transportFormGroupName).valid) {
      if (SectionName.SECTION_TRANSPORT != this.currentSectionName) {
        document.getElementById(SectionName.SECTION_TRANSPORT).classList.add('tabs-item-valid');
      }
    }
    if (this.formGroup.get(this.wagonDataFormGroupName).valid) {
      if (SectionName.SECTION_WAGON_DATA != this.currentSectionName) {
        document.getElementById(SectionName.SECTION_WAGON_DATA).classList.add('tabs-item-valid');
      }
    }
    if (this.formGroup.get(this.serviceFormGroupName).valid || this.formGroup.get(this.serviceFormGroupName).disabled) {
      if (SectionName.SECTION_SERVICE != this.currentSectionName) {
        document.getElementById(SectionName.SECTION_SERVICE).classList.add('tabs-item-valid');
      }
    }
    if (this.formGroup.get(this.commercialFormGroupName).valid) {
      if (SectionName.SECTION_COMMERCIAL != this.currentSectionName) {
        document.getElementById(SectionName.SECTION_COMMERCIAL).classList.add('tabs-item-valid');
      }
    }
    if (this.formGroup.get(this.senderPolicyFormGroupName).valid) {
      if (SectionName.SECTION_SENDER_POLICY != this.currentSectionName) {
        document.getElementById(SectionName.SECTION_SENDER_POLICY).classList.add('tabs-item-valid');
      }
    }
  }

  protected showUnlockButton(): boolean {
    return this.orderInfoData.railOrder.templateNumber && this.editMode && !this.orderInfoData.railOrder.orderKey?.orderNumber;
  }

  protected unlockOrderTemplate(): void {
    this.orderInfoData.railOrder.templateNumber = null;

    this.formGroup.enable({ emitEvent: false });
    this.updateAllSections();
  }
}
